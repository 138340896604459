import { ReactElement } from "react";
import HomePage from "../modules/front/pages/HomePage";
import Dashboard from "modules/dashboard/layout/Dashboard";
import FrontLayauot from "modules/front/layout/Front";
import Products from "modules/dashboard/pages/Products";
import Settings from "modules/dashboard/pages/Settings";
import ProductsList from "modules/front/pages/ProductsList";
import Users from "modules/dashboard/pages/Users";
import Prestamos from "modules/front/pages/Prestamos";
import mueblesImg from "assets/front/banners/categorias/muebles.png";
import tecnologiaImg from "assets/front/banners/categorias/tecnologia.png";
import electrodomesticosImg from "assets/front/banners/categorias/electrodomesticos.png";
import deportesImg from "assets/front/banners/categorias/deportes.png";
import prestamosImg from "assets/front/banners/categorias/prestamos.png";
import SelectProducts from "modules/dashboard/pages/SelectProducts";
import Banners from "modules/dashboard/pages/Banners";
export type Routes = {
  url: string;
  component?: ReactElement;
  title: string;
  flatIcon: string;
  showIf?: any;
  imgBanner?: any;
};

type IROUTES = {
  dashboard: {
    url: string;
    children: Routes[];
    component?: ReactElement;
    title: string;
    flatIcon: string;
    showIf?: any;
  };
  front: {
    url: string;
    children: Routes[];
    component?: ReactElement;
    title: string;
    flatIcon: string;
    showIf?: any;
  };
  login: {
    url: string;
    title: string;
    flatIcon: string;
    showIf?: any;

    component?: ReactElement;
  };
  restorePassword: {
    url: string;
    title: string;
    flatIcon: string;
    showIf?: any;

    component?: ReactElement;
  };
};

// eslint-disable-next-line react/no-array-index-key

export const rutas: IROUTES = {
  dashboard: {
    url: "/dashboard/*",
    title: "Dashboard",
    flatIcon: "",
    component: <Dashboard />,
    children: [
      {
        url: "products",
        title: "Productos",
        flatIcon: "fa-solid fa-bag-shopping",
        component: <SelectProducts />,
      },
      {
        url: "productsOptions",
        title: "Productos opciones",
        flatIcon: "fa-brands fa-product-hunt",
        component: <Products />,
      },
      {
        url: "banners",
        title: "Banners",
        flatIcon: "fa-solid fa-image",
        component: <Banners />,
      },
      {
        url: "settings",
        title: "Ajustes",
        flatIcon: "fa-solid fa-gear",
        component: <Settings />,
      },

      {
        url: "addUser",
        title: "Usuarios",
        flatIcon: "fa-solid fa-user",
        component: <Users />,
      },
    ],
  },
  front: {
    url: "/*",
    title: "productos",
    flatIcon: "",
    component: <FrontLayauot />,
    children: [
      {
        url: "home",
        title: "Home",
        flatIcon: "",
        component: <HomePage />,
        imgBanner: mueblesImg,
      },

      {
        url: "muebles",
        title: "Muebles",
        flatIcon: "",
        component: <ProductsList />,

        imgBanner: mueblesImg,
      },
      {
        url: "tecnologia",
        title: "Tecnología ",
        flatIcon: "",
        component: <ProductsList />,

        imgBanner: tecnologiaImg,
      },
      {
        url: "electrodomesticos",
        title: "Electrodomésticos",
        flatIcon: "",
        component: <ProductsList />,

        imgBanner: electrodomesticosImg,
      },
      {
        url: "deportes",
        title: "Deportes",
        flatIcon: "",
        component: <ProductsList />,
        imgBanner: deportesImg,
      },
      {
        url: "prestamos",
        title: "Préstamos",
        flatIcon: "",
        component: <Prestamos></Prestamos>,
        imgBanner: prestamosImg,
      },
    ],
  },
  login: {
    url: "login",
    title: "login",
    flatIcon: "",
  },
  restorePassword: {
    url: "restore-password",
    title: "restore-password",
    flatIcon: "",
  },
};
