// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyBIYrLu0q8miNThPvsQmgpGqnAxFMoTVEw",
  authDomain: "credipopularweb.firebaseapp.com",
  projectId: "credipopularweb",
  storageBucket: "credipopularweb.appspot.com",
  messagingSenderId: "103202966043",
  appId: "1:103202966043:web:a7cef15093ec00bad6c6be",
  measurementId: "G-2M3NDZTGPL",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

export const storage = getStorage(app);
export default app;
