import { useAppDispatch, useAppSelector } from "app/hooks";

// import ProductCard from "components/ProductCard";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Modal, Dropdown } from "flowbite-react";
import {
  getFormAction,
  getShowModal,
  setFormAction,
  setShowModal,
  getStatusModal,
  getuserFields,
  fetchAddUserAsync,
  fetchusersAsync,
} from "../../redux/userSlice";
import * as Yup from "yup";
import { fetchGetProductsAsync } from "modules/dashboard/redux/productSlice";

const UserSchema = Yup.object().shape({
  name: Yup.string().required("Nombre requerido"),
  lastName: Yup.string().required("Apellido requerido"),
  email: Yup.string().required("Correo requerido"),
  password: Yup.string().required("Contraseña requerida"),
  celular: Yup.string().notRequired(),
});

const UserCreate: React.FC = () => {
  const dispatch = useAppDispatch();
  const show = useAppSelector(getShowModal);
  const formAction = useAppSelector(getFormAction);
  const statusModal = useAppSelector(getStatusModal);
  const UserFields = useAppSelector(getuserFields);

  const initialValue =
    formAction === "update"
      ? UserFields
      : {
          name: "",
          lastName: "",
          celular: "",
          email: "",
          password: "",
        };

  useEffect(() => {}, [show]);

  return (
    <>
      {" "}
      <Dropdown
        label={<i className="fa-solid fa-ellipsis-vertical"></i>}
        arrowIcon={false}
        inline
      >
        <Dropdown.Item
          onClick={() => {
            dispatch(setShowModal(true));
            dispatch(setFormAction("add"));
          }}
        >
          Crear usuario
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            dispatch(fetchusersAsync());
          }}
        >
          Actualizar
        </Dropdown.Item>
      </Dropdown>
      <Modal dismissible show={show} className="fade">
        <Modal.Header className="flex flex-wrap justify-center">
          <div className="w-full px-4 flex justify-center">
            <h6 className="text-black text-xl font-bold">
              {formAction === "update" ? "Actualizar" : "Crear nuevo"} usuario
            </h6>
          </div>
        </Modal.Header>

        <Modal.Body>
          <Formik
            initialValues={initialValue}
            validationSchema={UserSchema}
            onSubmit={async (values) => {
              await dispatch(fetchAddUserAsync({ ...values }));
              await dispatch(fetchusersAsync());
              formAction === "update"
                ? await dispatch(fetchGetProductsAsync(true))
                : await dispatch(fetchGetProductsAsync());
            }}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form className="grid grid-cols-2" onSubmit={handleSubmit}>
                <div className=" col-span-1 mb-4 p-1">
                  <label htmlFor="val-name" className="text-label">
                    Nombre <span className="text-red-500">*</span>
                  </label>
                  <div className="input-group transparent-append mb-2">
                    {/* <span className="input-group-text">
                      <i className="fa fa-user"></i>
                    </span> */}
                    <input
                      type="text"
                      className={`form-control w-full ${
                        values.name && errors.name ? "is-invalid" : "is-valid"
                      }`}
                      id="val-name"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      autoComplete="off"
                    />
                    <div
                      className="invalid-feedback animated fadeInUp text-red-500"
                      style={{ display: "block" }}
                    >
                      {errors.name && errors.name}
                    </div>
                  </div>
                </div>
                <div className=" col-span-1 mb-4 p-1">
                  <label htmlFor="val-lastName" className="text-label">
                    Apellido<span className="text-red-500">*</span>
                  </label>
                  <div className="input-group transparent-append mb-2">
                    {/* <span className="input-group-text">
                      <i className="fa fa-user"></i>
                    </span> */}
                    <input
                      type="text"
                      className={`form-control w-full ${
                        values.lastName && errors.lastName
                          ? "is-invalid"
                          : "is-valid"
                      }`}
                      id="val-lastName"
                      name="lastName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastName}
                      autoComplete="off"
                    />
                    <div
                      className="invalid-feedback animated fadeInUp text-red-500"
                      style={{ display: "block" }}
                    >
                      {errors.lastName && errors.lastName}
                    </div>
                  </div>
                </div>
                <div className=" col-span-1 mb-4 p-1">
                  <label htmlFor="val-email" className="text-label">
                    Correo<span className="text-red-500">*</span>
                  </label>
                  <div className="input-group transparent-append mb-2">
                    {/* <span className="input-group-text">
                      <i className="fa fa-user"></i>
                    </span> */}
                    <input
                      type="email"
                      className={`form-control w-full ${
                        values.email && errors.email ? "is-invalid" : "is-valid"
                      }`}
                      id="val-email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      autoComplete="off"
                    />
                    <div
                      className="invalid-feedback animated fadeInUp text-red-500"
                      style={{ display: "block" }}
                    >
                      {errors.email && errors.email}
                    </div>
                  </div>
                </div>
                <div className=" col-span-1 mb-4 p-1">
                  <label htmlFor="val-celular" className="text-label">
                    Celular
                  </label>
                  <div className="input-group transparent-append mb-2">
                    {/* <span className="input-group-text">
                      <i className="fa fa-user"></i>
                    </span> */}
                    <input
                      type="text"
                      className={`form-control w-full ${
                        values.celular && errors.celular
                          ? "is-invalid"
                          : "is-valid"
                      }`}
                      id="val-celular"
                      name="celular"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.celular}
                      autoComplete="off"
                    />
                    <div
                      className="invalid-feedback animated fadeInUp text-red-500"
                      style={{ display: "block" }}
                    >
                      {errors.celular && errors.celular}
                    </div>
                  </div>
                </div>
                {formAction != "update" ? (
                  <div className=" col-span-1 mb-4 p-1">
                    <label htmlFor="val-password" className="text-label">
                      Contraseña<span className="text-red-500">*</span>
                    </label>
                    <div className="input-group transparent-append mb-2">
                      {/* <span className="input-group-text">
                      <i className="fa fa-user"></i>
                    </span> */}
                      <input
                        type="password"
                        className={`form-control w-full ${
                          values.password && errors.password
                            ? "is-invalid"
                            : "is-valid"
                        }`}
                        id="val-password"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        autoComplete="off"
                      />
                      <div
                        className="invalid-feedback animated fadeInUp text-red-500"
                        style={{ display: "block" }}
                      >
                        {errors.password && errors.password}
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {/* Otros campos similares */}

                <div className="col-span-2">
                  <button
                    type="submit"
                    className="lg:text-base bg-vinotinto hover:bg-red-800 text-white  py-1 px-6 mx-3 rounded-full"
                    disabled={isSubmitting}
                  >
                    {formAction === "update" ? "Actualizar" : "Guardar"}
                    {statusModal === "loading" && (
                      <>
                        <i className="fa fa-cog fa-spin fa-fw" />
                        <span className="sr-only">Loading...</span>
                      </>
                    )}
                  </button>

                  <button
                    className=" hover:bg-vinotinto hover:text-white px-6 py-1 hover:rounded-full mx-3"
                    type="button"
                    onClick={() => dispatch(setShowModal(false))}
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default UserCreate;
