import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "../../../firebase";
import axios from "axios";
// import { createAccountOrder } from "services/accountOrderService";
// import { addPlanProps, IPlan } from "utils/models/IPlan";
// import { IPlatform } from "utils/models/IPlatform";
import {
  showErrorForGetData,
  showErrorMessage,
  showSuccessMessage,
} from "share/serviceMessages";
import dayjs from "dayjs";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadString,
} from "firebase/storage";
const obj = "Banner";
type IBanner = {
  id: string;
  url: string;
  creationDate?: string;
};
type IBannerProduct = {
  id: string;
  url: string;
  type: string;
  creationDate?: string;
};
const uploadImage = async (img: string, name: string) => {
  console.log(img);
  const storageRef = ref(storage, `banners/${dayjs().unix()}-${name}.png`);
  await uploadString(storageRef, img, "data_url");
  return getDownloadURL(storageRef);
};

export const addBanner = async (payload: any) => {
  try {
    const creationDate = serverTimestamp();
    // const creationDate = serverTimestamp();
    console.log("data", payload);
    const imgUrl = await uploadImage(payload.url, "Banners");
    console.log(imgUrl);
    const docRef = await addDoc(collection(db, "BannersHome"), {
      url: imgUrl,
      creationDate,
    });

    // await createAccountOrder(docRef.id);
    showSuccessMessage({ obj, action: "add" });
    return docRef.id;
  } catch (error) {
    showErrorMessage({ obj, error, action: "add" });
    return Promise.reject();
  }
};

export const updateBannerHome = async (payload: any) => {
  try {
    const bannerRef = doc(db, "BannersHome", payload.id);

    const imgUrl = await uploadImage(
      payload.url,

      "Banners"
    );
    await updateDoc(bannerRef, {
      url: imgUrl,
    });

    showSuccessMessage({ obj, action: "update" });
  } catch (error) {
    showErrorMessage({ obj, error, action: "update" });
    return Promise.reject();
  }
};
export const updateBannerProduct = async (payload: any) => {
  try {
    const bannerRef = doc(db, "BannersProducts", payload.id);

    const imgUrl = await uploadImage(
      payload.url,

      "BannersP"
    );
    await updateDoc(bannerRef, {
      url: imgUrl,
    });

    showSuccessMessage({ obj, action: "update" });
  } catch (error) {
    showErrorMessage({ obj, error, action: "update" });
    return Promise.reject();
  }
};
export const deleteBanner = async (uid: string) => {
  try {
    await deleteDoc(doc(db, "BannersHome", uid));
    showSuccessMessage({ obj, action: "delete" });
  } catch (error) {
    showErrorMessage({ obj, error, action: "delete" });
    return Promise.reject();
  }
};

export const getBannersHome = async () => {
  try {
    let data: IBanner[] = [];
    // const querySnapshot = await getDocs(collection(db, "Banners"));
    const querySnapshot = await getDocs(
      query(collection(db, "BannersHome"), orderBy("creationDate", "asc"))
    );

    querySnapshot.forEach((doc) => {
      const banner = doc.data();
      const creationDate = dayjs(banner.creationDate.toDate()).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      data.push({
        id: doc.id,
        ...banner,

        creationDate,
      } as IBanner);
    });
    console.log("banner", data);
    return data;
  } catch (error) {
    showErrorForGetData({ obj: "banner", error });
    return Promise.reject();
  }
};

export const getBannersProducts = async () => {
  try {
    let data: IBannerProduct[] = [];
    // const querySnapshot = await getDocs(collection(db, "Banners"));
    const querySnapshot = await getDocs(
      query(collection(db, "BannersProducts"), orderBy("type", "asc"))
    );

    querySnapshot.forEach((doc) => {
      const banner = doc.data();

      data.push({
        id: doc.id,
        ...banner,
      } as IBannerProduct);
    });
    return data;
  } catch (error) {
    showErrorForGetData({ obj: "banner", error });
    return Promise.reject();
  }
};
