import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import {
  addSubCategory,
  getSubCategorys as getSubCategorysService,
  updateSubCategory,
} from "..//services/subCategoryService";
import { getCacheValue, setCacheValue } from "utils/cache";
import { ISubCategory, IAddSubCategory } from "utils/models//ICategorys";

type SubCategoryState = {
  id: string;
  icon?: string;
  fechaCreacion?: string;
  category: string;
  name: string;
  url?: string;
  subCategorys: ISubCategory[];
  showModal: boolean;
  formAction: "update" | "add";

  statusSubCategory: "idle" | "loading" | "success" | "fail";
  statusModal: "idle" | "loading" | "success" | "fail";
};

const initialState: SubCategoryState = {
  id: "",
  category: "",
  name: "",
  subCategorys: [],
  fechaCreacion: "",
  formAction: "add",
  showModal: false,
  statusSubCategory: "idle",
  statusModal: "idle",
};

export const fetchAddSubCategoryAsync = createAsyncThunk<
  Promise<any>,
  IAddSubCategory,
  { state: RootState }
>("auth/fetchAddSubCategoryAsync", async (props, { getState }) => {
  const {
    subCategorys: { formAction, id },
  } = getState();
  const res = await (formAction === "add"
    ? addSubCategory(props)
    : updateSubCategory({ ...props, id }));
  return res;
});

export const fetchGetSubCategorysAsync = createAsyncThunk(
  "SubCategorys/fetchGetSubCategorysAsync",
  async (forceFetch: boolean | undefined): Promise<ISubCategory[]> => {
    const cacheValue = getCacheValue("subCategorys");
    if (cacheValue && !forceFetch) {
      return cacheValue;
    }

    const response = await getSubCategorysService();
    setCacheValue("SubCategorys", response, 5);
    return response;
  }
);

export const subCategorysSlice = createSlice({
  name: "subCategorys",
  initialState,
  reducers: {
    setFormAction: (
      state,
      action: PayloadAction<SubCategoryState["formAction"]>
    ) => {
      state.formAction = action.payload;
    },
    setSubCategoryToEdit: (state, action: PayloadAction<ISubCategory>) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.category = action.payload.category;
      state.formAction = "update";
    },
    setShowModal: (
      state,
      action: PayloadAction<SubCategoryState["showModal"]>
    ) => {
      state.showModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetSubCategorysAsync.pending, (state) => {
        state.statusSubCategory = "loading";
      })
      .addCase(
        fetchGetSubCategorysAsync.fulfilled,
        (state, action: PayloadAction<ISubCategory[]>) => {
          state.statusSubCategory = "success";
          state.subCategorys = action.payload;
        }
      )
      .addCase(fetchGetSubCategorysAsync.rejected, (state) => {
        state.statusSubCategory = "fail";
      })
      .addCase(fetchAddSubCategoryAsync.pending, (state) => {
        state.statusModal = "loading";
      })
      .addCase(fetchAddSubCategoryAsync.fulfilled, (state) => {
        state.statusModal = "success";
        state.showModal = false;
      })
      .addCase(fetchAddSubCategoryAsync.rejected, (state) => {
        state.statusModal = "fail";
      });
  },
});

// Export actions
export const { setFormAction, setSubCategoryToEdit, setShowModal } =
  subCategorysSlice.actions;
// Definición de selectores
export const getSubCategoryFields = (state: RootState) => {
  return {
    name: state.subCategorys.name,
    category: state.subCategorys.category,
  };
};
export const getSubCategorys = (state: RootState) =>
  state.subCategorys.subCategorys;

export const getShowModal = (state: RootState) => state.subCategorys.showModal;
export const getFormAction = (state: RootState) =>
  state.subCategorys.formAction;
export const getStatusSubCategorys = (state: RootState) =>
  state.subCategorys.statusSubCategory;
export const getSubCategorysQuery = (state: RootState, query: string) => {
  if (query) {
    return state.subCategorys.subCategorys.filter(
      (d: any) =>
        d.category.toLowerCase().includes(query) ||
        d.name.toLowerCase().includes(query)
    );
  } else {
    return state.subCategorys.subCategorys;
  }
};
export const getStatusModal = (state: RootState) =>
  state.subCategorys.statusModal;

export default subCategorysSlice.reducer;
