import React, { useEffect, useState } from "react";
import { IProduct } from "utils/models/IProducts";
import {
  fetchAddCartProductAsync,
  fetchGetCartProductsAsync,
  getCartProducts,
} from "../redux/cartProductSlice";
import { query } from "firebase/firestore";
import { useAppDispatch, useAppSelector } from "app/hooks";
// Definición de la interfaz para las props
interface CardProductHomeProps {
  product: IProduct; // Tipo de dato para el product
}

// Definición de la interfaz para el tipo de dato Product
// interface Product {
//   id: string | number;
//   nombreDescriptivo: string;
//   codigo:string
//   descripcion?: string;
//   imagen: any;
// }

const CardProductHome: React.FC<CardProductHomeProps> = ({ product }) => {
  const [checkAdd, setCheckAdd] = useState(false);
  let cartProducts = useAppSelector((state) => getCartProducts(state, ""));

  const dispatch = useAppDispatch();
  useEffect(() => {
    console.log(cartProducts);

    const i = cartProducts.findIndex((obj) => obj.id === product.id);

    if (i !== -1) {
      setCheckAdd(true);
    }

    // eslint-disable-next-line no-use-before-define
  }, []);

  const addToCart = async () => {
    const isProductAdded = cartProducts.some(
      (cartProduct: any) => cartProduct.id === product.id
    );
    const updatedCartProducts = isProductAdded
      ? cartProducts.filter((cartProduct) => cartProduct.id !== product.id)
      : [...cartProducts, product];

    setCheckAdd(!isProductAdded);
    dispatch(fetchAddCartProductAsync(updatedCartProducts));
    dispatch(fetchGetCartProductsAsync());
  };
  return (
    <div className="flex w-full flex-col justify-between">
      <img
        className="w-full shadow-xl h-[200px] sm:h-[250px] lg:h-[300px] object-contain lg:object-fill"
        src={product.imagen}
        alt="imagen product card"
      />

      <h3 className="mt-2 lg:text-xl xl:text-2xl font-light">
        {product.nombreDescriptivo}
      </h3>

      <div className="w-full flex justify-end p-2">
        {checkAdd === false ? (
          <button
            type="button"
            onClick={() => addToCart()}
            className="lg:text-lg xl:text-xl bg-vinotinto hover:bg-red-800 text-white font-bold py-1 px-6 rounded-full"
          >
            Cotizar
          </button>
        ) : (
          <button
            type="button"
            onClick={() => addToCart()}
            className="lg:text-lg xl:text-xl bg-azul-button hover:bg-blue-600 text-white font-bold py-1 px-6 rounded-full"
          >
            Quitar
          </button>
        )}
      </div>
    </div>
  );
};

export default CardProductHome;
