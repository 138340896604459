import React, { useContext, useEffect } from "react";

/// React router dom
import { Navigate, Route, Routes } from "react-router-dom";

/// Layout

import { rutas } from "utils/routes";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getUser } from "modules/dashboard/redux/authSlice";

// import Error404 from "co";

const Markup: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);

  const paths = window.location.pathname.split("/");
  const path = paths[paths.length - 1];

  const pagePath = path.split("-").includes("page");

  useEffect(() => {}, []);

  return (
    <div>
      <Routes>
        <Route path={rutas.dashboard.url} element={rutas.dashboard.component} />
        {/* <Route path="*" element={<Error404 />} /> */}

        <Route path={rutas.front.url} element={rutas.front.component} />
        <Route path="/" element={<Navigate to="home" />} />
      </Routes>
    </div>
  );
};

export default Markup;
