import React, { useEffect } from "react";
import Header from "../components/Header";
import { Navigate, Route, Routes } from "react-router-dom";

import { rutas, Routes as IRoutes } from "utils/routes";
import SideBar from "../components/SideBar";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getCheckLogin, getStatusLogin } from "../redux/authSlice";
import { getAuth } from "firebase/auth";

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const statusUser = useAppSelector(getStatusLogin);

  const checkLogin = useAppSelector(getCheckLogin);
  useEffect(() => {}, []);
  return (
    <div className="flex items-stretch justify-stretch h-full min-h-screen">
      <SideBar />
      <div className=" w-full">
        <Header />
        <div className=" lg:px-10  mx-auto w-full h-full bg-blue-100 -m-24">
          <Routes>
            {rutas.dashboard.children.map((data: IRoutes, i: any) => (
              // eslint-disable-next-line react/no-array-index-key

              <Route key={i} path={`${data.url}`} element={data.component} />
            ))}
            {/* <Route path="*" element={<Error404 />} /> */}

            <Route path="/" element={<Navigate to="products" />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
