import React from "react";
import Header from "../components/header";
// react router dom

import { Navigate, Route, Routes } from "react-router-dom";

//
import Footer from "../components/footer";
import { rutas, Routes as IRoutes } from "utils/routes";
import HomePage from "../pages/HomePage";
import Error404 from "../components/Error404";
import Carrito from "../pages/Carrito";
import WhatsappButton from "../components/WhatsappButton";

const FrontLayauot = () => {
  return (
    <div className="flex relative flex-col justify-between   h-full min-h-screen">
      <Header />
      <WhatsappButton />
      <div className="h-full ">
        <Routes>
          {rutas.front.children.map((data: IRoutes, i: any) => (
            // eslint-disable-next-line react/no-array-index-key

            <Route key={i} path={`${data.url}`} element={data.component} />
          ))}
          <Route path="*" element={<Error404 />} />

          <Route index element={<HomePage />} />
          <Route path="carrito" element={<Carrito />} />
          <Route path="/" element={<Navigate to="home" />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default FrontLayauot;
