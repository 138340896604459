import React, { useEffect, useState } from "react";
import logo from "assets/productos/img/logo_transparente_azul_sintextos.png";
import { HiOutlineMinusSm, HiOutlinePlusSm } from "react-icons/hi";

import { twMerge } from "tailwind-merge";
import { Sidebar } from "flowbite-react";
import { Link, useNavigate } from "react-router-dom";
import { rutas } from "utils/routes";
import { getUserData, handleLogout } from "../services/authService";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getUser } from "../redux/authSlice";
import { IUser } from "utils/models/IUser";
const SideBar = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [collapseShow, setCollapseShow] = useState(false);
  const userdata = useAppSelector((state) => getUser(state));
  const [user, setUser] = useState<IUser>();
  const [lastIndex, setLastIndex] = useState(0);
  useEffect(() => {
    const getData = async () => {
      setUser(await getUserData(userdata.id));
    };
    setLastIndex(rutas.dashboard.children.length - 2);
    getData();
  }, []);
  const toggleCollapseShow = (bol: any) => {
    setCollapseShow(bol);
  };
  return (
    <div>
      <div
        onClick={() => setCollapseShow(false)}
        className={`${
          collapseShow
            ? "fixed lg:hidden  w-screen  lg:w-screen min-h-screen h-full flex flex-col gap-2 py-5 pl-5 text-base left-0 top-0  bg-black opacity-30 z-50"
            : "hidden"
        }`}
      ></div>
      <button
        className=" fixed rounded-full top-1 left-1 cursor-pointer text-white   z-10 lg:hidden px-3 py-1 text-xl leading-none bg-blue-800  border border-solid border-transparent"
        type="button"
        onClick={() => {
          toggleCollapseShow(!collapseShow);
        }}
      >
        <i className="fas fa-bars"></i>
      </button>

      <Sidebar
        aria-label=""
        className={`${
          collapseShow
            ? "block fixed h-full z-50  lg:block lg:relative"
            : "hidden relative h-full z-50  lg:block lg:relative"
        }`}
      >
        <Sidebar.Items>
          <Link
            to="/home"
            className="lg:block text-left lg:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold w-full px-0"
          >
            <figure className="flex justify-center border-b-[3px] border-red-700 px-4 lg:px-6">
              <img className="h-[50px]" src={logo} alt="" />
            </figure>
          </Link>
          <Sidebar.ItemGroup>
            <div>
              <p className="text-center text-lg">
                {user?.name + " " + user?.lastName}
              </p>
              {/* <p className="text-center text-sm lg:text-sm">
                {user?.rol === "admin" ? "Administrador" : "empleado"}
              </p> */}
              <p>{user?.id}</p>
            </div>
            {/* <Sidebar.Collapse
              // icon={HiShoppingBag}
              label="Pagina"
              renderChevronIcon={(theme, open) => {
                const IconComponent = open ? HiOutlineMinusSm : HiOutlinePlusSm;

                return (
                  <IconComponent
                    aria-hidden
                    className={twMerge(
                      theme.label.icon.open[open ? "on" : "off"]
                    )}
                  />
                );
              }}
            > */}
            {user?.rol === "admin"
              ? rutas.dashboard.children.map((ruta: any, index: any) => (
                  <Sidebar.Item
                    key={index}
                    className="text-"
                    onClick={() => toggleCollapseShow(false)}
                  >
                    <Link
                      to={ruta.url}
                      className="
          text-black rounded-l-lg pl-4
        "
                    >
                      <i className={`${ruta.flatIcon} mr-2 text-sm`}></i>
                      {ruta.title}
                    </Link>
                  </Sidebar.Item>
                ))
              : rutas.dashboard.children
                  .slice(0, lastIndex)
                  .map((ruta: any, index: any) => (
                    <Sidebar.Item
                      key={index}
                      className="text-"
                      onClick={() => toggleCollapseShow(false)}
                    >
                      <Link
                        to={ruta.url}
                        className="
                       text-black rounded-l-lg pl-4
      "
                      >
                        <i className={`${ruta.flatIcon} mr-2 text-sm`}></i>
                        {ruta.title}
                      </Link>
                    </Sidebar.Item>
                  ))}
            {/* </Sidebar.Collapse> */}
            <Sidebar.Item>
              <button
                className=""
                type="button"
                onClick={() => {
                  localStorage.clear();
                  handleLogout(navigate, dispatch);
                }}
              >
                <i className="fa-solid fa-right-to-bracket"></i> Salir
              </button>
            </Sidebar.Item>
          </Sidebar.ItemGroup>
        </Sidebar.Items>
      </Sidebar>
    </div>
  );
  // return (
  //   <nav className="lg:left-0 lg:block lg:fixed lg:top-0 lg:bottom-0 lg:overflow-y-auto lg:flex-row lg:flex-nowrap lg:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative lg:w-64 z-10 py-4">
  //     <div className="lg:flex-col lg:items-stretch lg:min-h-full lg:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
  //       {/* Toggler */}
  //       <button
  //         className="cursor-pointer text-black opacity-50 lg:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
  //         type="button"
  //         onClick={() => toggleCollapseShow("bg-white m-2 py-3 px-6")}
  //       >
  //         <i className="fas fa-bars"></i>
  //       </button>
  //       {/* Brand */}
  //       <a
  //         href="/"
  //         className="lg:block text-left lg:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold w-full px-0"
  //       >
  //         <figure className="flex justify-center border-b-[3px] border-red-700 px-4 lg:px-6">
  //           <img className="h-[50px]" src={logo} alt="" />
  //         </figure>
  //       </a>
  //       {/* User */}

  //       <div
  //         className={`lg:flex lg:flex-col lg:items-stretch lg:opacity-100 lg:relative lg:mt-2 lg:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded ${collapseShow}`}
  //       >
  //         {/* Collapse header */}
  //         <div className="lg:min-w-full lg:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
  //           <div className="flex flex-wrap">
  //             <div className="w-6/12">
  //               <a
  //                 href="/"
  //                 className="lg:block text-left lg:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
  //               >
  //                 <figure className="flex justify-center border-b-[3px] border-red-700 mt-5 px-4 lg:px-10">
  //                   <img className="h-[50px]" src={logo2} alt="" />
  //                 </figure>
  //               </a>
  //             </div>
  //             <div className="w-6/12 flex justify-end">
  //               <button
  //                 type="button"
  //                 className="cursor-pointer text-black opacity-50 lg:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
  //                 onClick={() => toggleCollapseShow("hidden")}
  //               >
  //                 <i className="fas fa-times"></i>
  //               </button>
  //             </div>
  //           </div>
  //         </div>
  //         {/* Form */}
  //         <form className="mt-6 mb-4 lg:hidden">
  //           <div className="mb-3 pt-0">
  //             <input
  //               type="text"
  //               placeholder="Search"
  //               className="border-0 px-3 py-2 h-12 border-solid border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
  //             />
  //           </div>
  //         </form>
  //         {/* Divider */}
  //         {/* Heading */}
  //         <h6 className="lg:min-w-full px-4 text-cafe text-sm lg:text-base lg:text-base lg:text-lg uppercase font-bold block pt-1 no-underline">
  //           Admin
  //         </h6>
  //         {/* Navigation */}
  //         <ul className="lg:flex-col px-4 pl-7 lg:min-w-full flex flex-col list-none">
  //           {rutas.dashboard.children.map((ruta: any, index: any) => (
  //             <li key={index} className="text-">
  //               <Link
  //                 to={ruta.url}
  //                 className={`text-sm lg:text-base lg:text-base uppercase py-2 font-bold block ${
  //                   !collapseShow && "text-black bg-white rounded-l-lg pl-4"
  //                 } ${
  //                   collapseShow &&
  //                   "text-white hover:text-azul-principal hover:bg-white hover:rounded-l-lg hover:pl-4"
  //                 }`}
  //               >
  //                 <i className={`${ruta.flatIcon} mr-2 text-sm`}></i>
  //                 {ruta.title}
  //               </Link>
  //             </li>
  //           ))}

  //           {/* <li className="items-center">
  //             <a href="/" className="text-sm lg:text-base uppercase py-3 font-bold block">
  //               <i className="fas fa-tools mr-2 text-sm"></i> Settings
  //             </a>
  //           </li>
  //           <li className="items-center">
  //             <a href="/" className="text-sm lg:text-base uppercase py-3 font-bold block">
  //               <i className="fas fa-table mr-2 text-sm"></i> Tables
  //             </a>
  //           </li>
  //           <li className="items-center">
  //             <a href="/" className="text-sm lg:text-base uppercase py-3 font-bold block">
  //               <i className="fas fa-map-marked mr-2 text-sm"></i> Maps
  //             </a>
  //           </li> */}
  //         </ul>
  //       </div>
  //       <button
  //         className=""
  //         type="button"
  //         onClick={() => handleLogout(navigate, dispatch)}
  //       >
  //         {" "}
  //         Salir
  //       </button>
  //     </div>
  //   </nav>
  // );
};

export default SideBar;
