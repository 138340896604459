import React from "react";

const WhatsappButton = () => {
  return (
    <div className="fixed bottom-2 right-2  h-12 w-12 rounded-full flex justify-center items-center z-30 bg-[#25d366] ">
      <a
        href="https://api.whatsapp.com/send?phone=+573105797539&text=Hola%21%20Quisiera%20m%C3%A1s%20informacion%C3%B3n%20sobre%20tus%C3%B3n%20productos%202."
        target="_blank"
        className="  "
      >
        <i className="fa fa-whatsapp my-float text-white text-xl"></i>
      </a>
    </div>
  );
};

export default WhatsappButton;
