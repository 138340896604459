import React, { useEffect, Suspense, lazy } from "react";

import logo from "./logo.svg";

import dayjs from "dayjs";
import "./App.css";
import { rutas } from "utils/routes";
import {
  BrowserRouter,
  Navigate,
  Route,
  Router,
  Routes,
  useNavigate,
} from "react-router-dom";

import { store } from "app/store";
import FrontLayauot from "./modules/front/layout/Front";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  getCheckLogin,
  isAuthenticated,
  loginConfirmedAction,
} from "modules/dashboard/redux/authSlice";
import {
  getUserData,
  handleLogin,
  handleLogout,
} from "modules/dashboard/services/authService";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import firebaseApp from "./firebase";
import LoaderFullPage from "share/loaderFullPage/LoaderFullPage";

import es from "dayjs/locale/es";
import RestorePassword from "modules/login/Pages/RestorePassword";
import Markup from "Markup";
import Pdfgenerate from "modules/front/pdf/Pdfgenerate";

const LoginPage = lazy(() => import("./modules/login/Pages/LoginPage"));
const App: React.FC = () => {
  dayjs.locale(es);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const checkLogin = useAppSelector(getCheckLogin);
  const authenticated = useAppSelector(isAuthenticated);
  const auth = getAuth(firebaseApp);

  const unSubscribe = onAuthStateChanged(auth, async (user: any) => {
    if (user) {
      if (!authenticated) {
        const extraData = await getUserData(user.uid);
        if (
          store.getState().auth.checkLogin === "empty" &&
          Object.keys(extraData).length !== 0
        ) {
          dispatch(
            loginConfirmedAction({
              id: user.uid,
              lastName: user.lastName,
              email: user.email || "",
              name: extraData?.name,
              celular: user.celular,
            })
          );
          handleLogin(navigate, dispatch);
        }
      }
    } else {
      handleLogout(navigate, dispatch);
    }
  });
  // unSubscribe function to avoid overloading components
  useEffect(() => {
    return () => {
      unSubscribe();
    };
  }, [unSubscribe]);
  const routes = (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/restore-password" element={<RestorePassword />} />
      <Route path={rutas.front.url} element={rutas.front.component} />

      <Route path="/" element={<Navigate to="home" />} />
    </Routes>
  );
  if (checkLogin === "empty") {
    return <LoaderFullPage />;
  }
  if (authenticated) {
    return (
      <Suspense fallback={<LoaderFullPage />}>
        <Markup />
      </Suspense>
    );
  } else {
    return (
      <div className="vh-100">
        <Suspense fallback={<LoaderFullPage />}>{routes}</Suspense>
      </div>
    );
  }
  // }else {
  //   return (
  //     <div className="h-full min-h-screen">
  //       <Suspense fallback={<LoaderFullPage />}>
  //         <Routes>
  //           {authenticated ? (
  //             <Route
  //               path={rutas.dashboard.url}
  //               element={rutas.dashboard.component}
  //             />
  //           ) : (
  //             <></>
  //           )}

  //         </Routes>
  //       </Suspense>
  //     </div>
  //   );
  // }
};

export default App;
