import React from "react";

import { Spinner } from "flowbite-react";
type ILoaderProps = {
  small?: boolean;
  inline?: boolean;
};

const Loader: React.FC<ILoaderProps> = ({ small, inline }) => {
  const size = small ? "sm" : undefined;

  return (
    <div className={inline ? "d-inline" : "text-center"}>
      <Spinner color="info" aria-label="spinner" size={size} />
    </div>
  );
};

Loader.defaultProps = {
  small: false,
  inline: false,
};

export default Loader;
