import React, { useEffect, useState } from "react";
import SideBar from "../components/products/SideBar";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  fetchGetProductsForCategoryAsync,
  getStatusProducts,
} from "../redux/productSlice";
import {
  fetchGetSubCategorysForCategoryAsync,
  getStatusSubCategorys,
} from "../redux/subCategorySlice";
import TableProducts from "../components/products/TableProducts";
import {
  fetchGetCartProductsAsync,
  getStatusCartProducts,
} from "../redux/cartProductSlice";
import LoaderBlue from "share/LoaderBlue";
import { rutas } from "utils/routes";
import { query } from "firebase/firestore";
import {
  fetchGetBannersProductsAsync,
  getBannersProductsQuery,
  getStatusBannersProducts,
} from "modules/dashboard/redux/bannersSlice";

const ProductsList = () => {
  const dispatch = useAppDispatch();
  const ruta = window.location.pathname;
  const valorDespuesDeBarra = ruta.split("/")[1];
  const [title, setTitle] = useState("");
  const [banner, setBanner] = useState("");
  const statusProducts = useAppSelector(getStatusProducts);
  const statusSubCategorys = useAppSelector(getStatusSubCategorys);

  const statusBanners = useAppSelector(getStatusBannersProducts);
  const banners = useAppSelector((state) =>
    getBannersProductsQuery(state, valorDespuesDeBarra)
  );
  const statusCartProducts = useAppSelector(getStatusCartProducts);
  const [subcategoria, setSubcategoria] = useState("");

  const cambiarSubcategoria = (nuevaSubcategoria: string) => {
    setSubcategoria(nuevaSubcategoria);
  };

  const reiniciarSubcategoria = () => {
    setSubcategoria("");
  };
  useEffect(() => {
    getTitleFromPath(valorDespuesDeBarra, rutas.front);
    if (statusBanners === "idle" || statusBanners === "loading") {
      dispatch(fetchGetBannersProductsAsync(true));
    }
    if (statusProducts === "idle")
      dispatch(
        fetchGetProductsForCategoryAsync({
          name: valorDespuesDeBarra,
          forceFetch: true,
        })
      );
    if (statusSubCategorys === "idle")
      dispatch(
        fetchGetSubCategorysForCategoryAsync({
          name: valorDespuesDeBarra,
          forceFetch: true,
        })
      );

    if (statusCartProducts === "idle") {
      dispatch(fetchGetCartProductsAsync());
      dispatch(fetchGetCartProductsAsync());
    }
  }, [
    dispatch,
    statusBanners,
    statusCartProducts,
    statusProducts,
    statusSubCategorys,
    valorDespuesDeBarra,
  ]);
  function getTitleFromPath(path: string, frontConfig: any) {
    // Iterar sobre los elementos dentro de frontConfig.children
    for (let i = 0; i < frontConfig.children.length; i++) {
      const child = frontConfig.children[i];

      // Si la URL del niño coincide exactamente con la ruta dada
      if (child.url === path) {
        // Devolver el título del niño
        setTitle(child.title);
      }
    }

    // Si no se encuentra ninguna coincidencia, devolver null
    return null;
  }
  useEffect(() => {
    if (banners.length > 0) {
      setBanner(banners[0].url);
    }
  }, [banners, valorDespuesDeBarra]);
  useEffect(() => {
    setSubcategoria("");
  }, [valorDespuesDeBarra]);
  return (
    <div className=" h-full w-full flex flex-col  justify-start items-center min-h-[80vh]">
      <div className=" relative h-[120px]  sm:h-[160px] md:h-[200px] lg:h-[300px] xl:h-[350px] 2xl:h-[400px]  w-full text-white  ">
        {statusBanners === "success" ? (
          <img
            className="w-full h-full absolute z-0 t-0 object-fill  object-center"
            src={banner}
            alt=""
          />
        ) : (
          <></>
        )}
        <div className="w-full h-full absolute z-0 t-0 bg-black opacity-20 "></div>

        <div className=" relative z-10 max-w-7xl h-full flex items-center justify-center mx-auto">
          <h1 className="text-2xl drop-shadow  text-center z-10 font-bold sm:text-4xl md:text-5xl lg:text-6xl">
            {title}
          </h1>
          {/* <p className="mt-4 text-xl">Descubre nuestras ofertas exclusivas.</p> */}
          {/* <div className="mt-8">
            <a
              href="#"
              className="inline-block bg-white text-blue-900 py-3 px-6 rounded-lg font-semibold shadow-md hover:bg-blue-100"
            >
              Ver más
            </a>
          </div> */}
        </div>
      </div>
      <div className="flex relative container max-w-screen-xl h-full py-2 ">
        {statusSubCategorys === "success" ? (
          <SideBar
            subcategoria={subcategoria}
            cambiarSubcategoria={cambiarSubcategoria}
          ></SideBar>
        ) : (
          <></>
        )}
        <div className="w-full flex gap-4 p-4">
          {statusSubCategorys === "success" && statusProducts === "success" ? (
            <TableProducts
              subcategoria={subcategoria}
              reiniciarSubcategoria={reiniciarSubcategoria}
            ></TableProducts>
          ) : (
            <div className="h-full min-h-[500px] w-full flex justify-center items-center">
              <LoaderBlue></LoaderBlue>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductsList;
