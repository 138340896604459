import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  fetchGetSubCategorysAsync,
  getSubCategorysQuery,
  setShowModal,
  setSubCategoryToEdit,
} from "modules/dashboard/redux/subCategorySlice";
import { deleteSubCategory } from "modules/dashboard/services/subCategoryService";

import React, { useEffect, useRef, useState } from "react";
import Pagination from "share/Pagination";
import SearchField from "share/SearchField";
import Swal from "sweetalert2";
import { ISubCategory } from "utils/models/ICategorys";

const SubCategorysTable = ({ color }: any) => {
  const dispatch = useAppDispatch();

  const [query, setQuery] = useState("");
  const subCategorys = useAppSelector((state) =>
    getSubCategorysQuery(state, query)
  );
  const sort = 5;
  const paggination = Array(Math.ceil(subCategorys.length / sort))
    .fill(0)
    .map((_, i) => i + 1);

  const activePag = useRef(0);
  const [data, setData] = useState(
    subCategorys.slice(activePag.current * sort, (activePag.current + 1) * sort)
  );

  const onChangePagination = (i: number) => {
    activePag.current = i;
    setData(
      subCategorys.slice(
        activePag.current * sort,
        (activePag.current + 1) * sort
      )
    );
  };
  const onClickDeleteSubCategory = (id: string) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "Una vez eliminado, ¡no podrá recuperar la SubCategoria !",
      icon: "warning",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then((res) => {
      if (res.isConfirmed) {
        deleteSubCategory(id).then(() => {
          dispatch(fetchGetSubCategorysAsync(true));
        });
      }
    });
  };
  const onClickUpdateSubCategory = (subCategory: ISubCategory) => {
    dispatch(setSubCategoryToEdit(subCategory));
    dispatch(setShowModal(true));
  };
  useEffect(() => {
    activePag.current = 0;
    setData(subCategorys.slice(0 * sort, (0 + 1) * sort));
  }, [query]);

  return (
    <div
      className={`relative p-2 flex flex-col min-w-0 break-words w-full mb-6 rounded `}
    >
      <div className="rounded-t mb-0 px-4 border-0">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full px-4 max-w-full mb-10 ">
            <div className=" absolute top-0 right-0 flex justify-end mb-2">
              <SearchField value={query} onChange={setQuery} />
            </div>
          </div>
        </div>
      </div>
      <div className="block w-full overflow-x-auto">
        {/* Projects table */}
        <table className="items-center w-full bg-transparent border-collapse">
          <thead>
            <tr>
              <th
                className={`px-2 align-middle border border-solid py-3 text-sm lg:text-base uppercase border-l-0 border-r-0  font-semibold text-left ${
                  color === "light"
                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                    : "bg-red-800 text-red-300 border-red-700"
                }`}
              >
                Nombre
              </th>
              <th
                className={`px-2 align-middle border border-solid py-3 text-sm lg:text-base uppercase border-l-0 border-r-0  font-semibold text-left ${
                  color === "light"
                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                    : "bg-red-800 text-red-300 border-red-700"
                }`}
              >
                Categoría padre
              </th>

              <th
                className={`px-2 text-center max-w-[80px] align-middle border border-solid py-3 text-sm lg:text-base uppercase border-l-0 border-r-0  font-semibold ${
                  color === "light"
                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                    : "bg-red-800 text-red-300 border-red-700"
                }`}
              >
                opciones
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((p: ISubCategory) => (
              <tr key={p.id}>
                <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-sm lg:text-base  p-2">
                  {p.name}
                </td>

                <td className="border-t-0 px-4 align-middle text-center border-l-0 border-r-0 text-sm lg:text-base  p-2">
                  <div className="flex items-center">{p.category}</div>
                </td>

                <td className="border-t-0 max-w-[80px] px-4 align-middle border-l-0 border-r-0 text-sm lg:text-base  p-2">
                  <div className="w-full flex justify-center ">
                    <button
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm lg:text-base px-2 py-0.5  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                      onClick={() => onClickUpdateSubCategory(p)}
                    >
                      <i className="fa fa-pencil" />
                    </button>

                    <button
                      className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm lg:text-base px-2 py-0.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                      onClick={() => onClickDeleteSubCategory(p.id)}
                    >
                      <i className="fa fa-trash" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="md:flex text-center w-full justify-between items-center mt-3">
          <div className="dataTables_info text-sm  min-w-[100px]">
            Mostrando del {activePag.current * sort + 1} al{" "}
            {subCategorys.length > (activePag.current + 1) * sort
              ? (activePag.current + 1) * sort
              : subCategorys.length}{" "}
            de {subCategorys.length} subcategorias
          </div>
          <div className="dataTables_paginate paging_simple_numbers overflow-x-auto ">
            <Pagination
              size="sm"
              gutter
              variant="primary"
              activePag={activePag}
              onChangePagination={onChangePagination}
              limit={paggination.length}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubCategorysTable;
