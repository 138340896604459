import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../../firebase";
// import { createAccountOrder } from "services/accountOrderService";
// import { addPlanProps, IPlan } from "utils/models/IPlan";
// import { IPlatform } from "utils/models/IPlatform";
import {
  showErrorForGetData,
  showErrorMessage,
  showSuccess,
  showSuccessMessage,
} from "share/serviceMessages";
import dayjs from "dayjs";
import {
  IAddSubCategory,
  ICategory,
  ISubCategory,
} from "utils/models/ICategorys";
const obj = "Categorias";

// const uploadImage = async (img: string, name: string, category?: string) => {
//   const storageRef = ref(
//     storage,
//     `products/${category}/${dayjs().unix()}-${name}.png`
//   );
//   await uploadString(storageRef, img, "data_url");
//   return getDownloadURL(storageRef);
// };

export const addSubCategory = async (payload: IAddSubCategory) => {
  try {
    const creationDate = serverTimestamp();
    // const creationDate = serverTimestamp();

    const docRef = await addDoc(collection(db, "Categorys"), {
      category: payload.category,
      name: payload.name,
      fechaCreacion: creationDate,
    });

    // await createAccountOrder(docRef.id);
    showSuccessMessage({ obj, action: "add" });
    return docRef.id;
  } catch (error) {
    showErrorMessage({ obj, error, action: "add" });
    console.log("error", error);
    return Promise.reject();
  }
};

export const updateSubCategory = async (payload: ISubCategory) => {
  try {
    const categoryRef = doc(db, "Categorys", payload.id);

    await updateDoc(categoryRef, {
      category: payload.category,
      name: payload.name,
    });
    const productsRef = collection(db, "Products");
    const querySnapshot = await getDocs(
      query(productsRef, where("uidSubCategory", "==", payload.id))
    );

    // Iterar sobre los productos encontrados y actualizar el campo sucategorianame
    querySnapshot.forEach(async (doc) => {
      await updateDoc(doc.ref, { subCategoria: payload.name });
      console.log(`Actualizado el producto con ID: ${doc.id}`);
    });
    showSuccessMessage({ obj, action: "update" });
  } catch (error) {
    showErrorMessage({ obj, error, action: "update" });
    return Promise.reject();
  }
};

export const deleteSubCategory = async (uid: string) => {
  try {
    const querySnapshot = await getDocs(
      query(collection(db, "Products"), where("uidSubCategory", "==", uid))
    );
    if (querySnapshot.docs.length === 0) {
      await deleteDoc(doc(db, "Categorys", uid));

      showSuccessMessage({ obj, action: "delete" });
    } else {
      showErrorMessage({
        obj,
        error: "Tiene productos vinculados a esta subcategoria",
        action: "delete",
      });
    }
  } catch (error) {
    showErrorMessage({ obj, error, action: "delete" });
    return Promise.reject();
  }
};

export const getSubCategorys = async () => {
  try {
    // const querySnapshot = await getDocs(collection(db, "Categorys"));
    const querySnapshot = await getDocs(
      query(collection(db, "Categorys"), orderBy("category", "desc"))
    );
    let data: ISubCategory[] = [];
    querySnapshot.forEach((doc) => {
      const product = doc.data();
      const fechaCreacion = dayjs(product.fechaCreacion.toDate()).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      data.push({
        id: doc.id,
        category: product.category,
        name: product.name,
        fechaCreacion,
      } as ISubCategory);
    });

    return data;
  } catch (error) {
    showErrorForGetData({ obj: "Subcategorias", error });
    return Promise.reject();
  }
};
