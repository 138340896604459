import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  fetchGetProductsAsync,
  getProductsQuery,
  setProductToEdit,
  setShowModal,
} from "modules/dashboard/redux/productSlice";
import { deleteProduct } from "modules/dashboard/services/productService";
import React, { useEffect, useRef, useState } from "react";
import Pagination from "share/Pagination";
import SearchField from "share/SearchField";
import Swal from "sweetalert2";
import { IProduct } from "utils/models/IProducts";

const ProductTable = ({ color }: any) => {
  const dispatch = useAppDispatch();

  const [query, setQuery] = useState("");
  const products = useAppSelector((state) => getProductsQuery(state, query));
  const sort = 5;
  const paggination = Array(Math.ceil(products.length / sort))
    .fill(0)
    .map((_, i) => i + 1);

  const activePag = useRef(0);
  const [data, setData] = useState(
    products.slice(activePag.current * sort, (activePag.current + 1) * sort)
  );

  const onChangePagination = (i: number) => {
    activePag.current = i;
    setData(
      products.slice(activePag.current * sort, (activePag.current + 1) * sort)
    );
  };
  const onClickDeleteProduct = (id: string) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "Una vez eliminado, ¡no podrá recuperar el producto!",
      icon: "warning",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then((res) => {
      if (res.isConfirmed) {
        deleteProduct(id).then(() => {
          dispatch(fetchGetProductsAsync(true));
        });
      }
    });
  };
  const onClickUpdateProduct = (product: IProduct) => {
    dispatch(setProductToEdit(product));
    dispatch(setShowModal(true));
  };
  useEffect(() => {
    activePag.current = 0;
    setData(products.slice(0 * sort, (0 + 1) * sort));
    // setData(products.slice(0 * sort, (0 + 1) * sort));
  }, [query]);

  return (
    <div
      className={`relative p-2 flex flex-col min-w-0 break-words w-full mb-6 rounded `}
    >
      <div className="rounded-t mb-0 px-4 border-0">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full px-4 max-w-full mb-10 ">
            <div className=" absolute top-0 right-0 flex justify-end mb-2">
              <SearchField value={query} onChange={setQuery} />
            </div>
          </div>
        </div>
      </div>
      <div className="block w-full overflow-x-auto">
        {/* Projects table */}
        <table className="items-center w-full bg-transparent border-collapse min-w-[1500px]">
          <thead>
            <tr>
              <th
                className={`px-4 text-center align-middle border border-solid py-3 text-sm lg:text-base uppercase border-l-0 border-r-0  font-semibold  ${
                  color === "light"
                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                    : "bg-red-800 text-red-300 border-red-700"
                }`}
              >
                Imagen
              </th>
              <th
                className={`px-4 align-middle border   border-solid py-3 text-sm lg:text-base uppercase border-l-0 border-r-0  font-semibold text-left ${
                  color === "light"
                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                    : "bg-red-800 text-red-300 border-red-700"
                }`}
              >
                Nombre
              </th>
              <th
                className={`px-4 align-middle border border-solid py-3 text-sm lg:text-base uppercase border-l-0 border-r-0  font-semibold text-left ${
                  color === "light"
                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                    : "bg-red-800 text-red-300 border-red-700"
                }`}
              >
                Precio
              </th>
              <th
                className={`px-4 align-middle border border-solid py-3 text-sm lg:text-base uppercase border-l-0 border-r-0  font-semibold text-left ${
                  color === "light"
                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                    : "bg-red-800 text-red-300 border-red-700"
                }`}
              >
                Código
              </th>
              <th
                className={`px-4 align-middle border border-solid py-3 text-sm lg:text-base uppercase border-l-0 border-r-0  font-semibold text-left ${
                  color === "light"
                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                    : "bg-red-800 text-red-300 border-red-700"
                }`}
              >
                Categoría
              </th>
              <th
                className={`px-4 align-middle border border-solid py-3 text-sm lg:text-base uppercase border-l-0 border-r-0  font-semibold text-left ${
                  color === "light"
                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                    : "bg-red-800 text-red-300 border-red-700"
                }`}
              >
                Subcategoria
              </th>
              <th
                className={`px-4 align-middle   border border-solid py-3 text-sm lg:text-base uppercase border-l-0 border-r-0  font-semibold text-left ${
                  color === "light"
                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                    : "bg-red-800 text-red-300 border-red-700"
                }`}
              >
                Descripción
              </th>
              <th
                className={`px-2 text-center max-w-[80px] align-middle border border-solid py-3 text-sm lg:text-base uppercase border-l-0 border-r-0  font-semibold ${
                  color === "light"
                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                    : "bg-red-800 text-red-300 border-red-700"
                }`}
              >
                opciones
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((p) => (
              <tr key={p.id}>
                <td className="flex justify-center">
                  <img
                    src={p.imagen}
                    className="h-20 w-24 bg-white rounded border"
                    alt="..."
                  />
                </td>
                <td className="border-t-0 px-4  align-middle border-l-0 border-r-0 text-sm lg:text-base  p-2">
                  {p.nombreDescriptivo}
                </td>
                <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-sm lg:text-base  p-2">
                  {p.precio}
                </td>
                <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-sm lg:text-base  p-2">
                  {p.codigo}
                </td>
                <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-sm lg:text-base  p-2">
                  <div className="flex items-center">{p.categoria}</div>
                </td>
                <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-sm lg:text-base  p-2">
                  <div className="flex items-center">{p.subCategoria}</div>
                </td>
                <td className="border-t-0  px-4 align-middle border-l-0 border-r-0 text-sm lg:text-base  p-2">
                  <p>{p.descripcion}</p>
                </td>
                <td className="border-t-0 w-[80px] px-4 align-middle border-l-0 border-r-0 text-sm lg:text-base  p-2">
                  <div className="w-full flex justify-center ">
                    <button
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-0.5  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                      onClick={() => onClickUpdateProduct(p)}
                    >
                      <i className="fa fa-pencil" />
                    </button>

                    <button
                      className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-2 py-0.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                      onClick={() => onClickDeleteProduct(p.id)}
                    >
                      <i className="fa fa-trash" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="md:flex text-center  justify-between items-center mt-3">
        <div className="dataTables_info text-sm lg:text-base">
          Mostrando del {activePag.current * sort + 1} al{" "}
          {products.length > (activePag.current + 1) * sort
            ? (activePag.current + 1) * sort
            : products.length}{" "}
          de {products.length} Productos
        </div>
        <div className="dataTables_paginate paging_simple_numbers overflow-x-auto">
          <Pagination
            size="sm"
            gutter
            variant="primary"
            activePag={activePag}
            onChangePagination={onChangePagination}
            limit={paggination.length}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductTable;
