import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  fetchGetPeticionesAsync,
  getPeticionesQuery,
} from "modules/dashboard/redux/peticionesSlice";
import { deleteProduct } from "modules/dashboard/services/peticioensService";

import React, { useEffect, useRef, useState } from "react";
import Pagination from "share/Pagination";
import SearchField from "share/SearchField";
import Swal from "sweetalert2";

const PeticionesPrestamosTable = ({ color }: any) => {
  const dispatch = useAppDispatch();

  const [query, setQuery] = useState("");
  const peticiones = useAppSelector((state) =>
    getPeticionesQuery(state, query)
  );
  const sort = 5;
  const paggination = Array(Math.ceil(peticiones.length / sort))
    .fill(0)
    .map((_, i) => i + 1);

  const activePag = useRef(0);
  const [data, setData] = useState(
    peticiones.slice(activePag.current * sort, (activePag.current + 1) * sort)
  );

  const onChangePagination = (i: number) => {
    activePag.current = i;
    setData(
      peticiones.slice(activePag.current * sort, (activePag.current + 1) * sort)
    );
  };
  const onClickDeletePeticion = (id: string) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "Una vez eliminado, ¡no podrá recuperar la petición de préstamo!",
      icon: "warning",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then((res) => {
      if (res.isConfirmed) {
        deleteProduct(id).then(() => {
          dispatch(fetchGetPeticionesAsync(true));
        });
      }
    });
  };
  // const onClickUpdateSubCategory = (subCategory: ISubCategory) => {
  //   dispatch(setSubCategoryToEdit(subCategory));
  //   dispatch(setShowModal(true));
  // };
  useEffect(() => {
    activePag.current = 0;
    setData(peticiones.slice(0 * sort, (0 + 1) * sort));
  }, [query]);

  return (
    <div
      className={`relative p-2 flex flex-col min-w-0 break-words w-full mb-6 rounded `}
    >
      <div className="rounded-t mb-0 px-4 border-0">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full px-4 max-w-full mb-10 ">
            <div className=" absolute top-0 right-0 flex justify-end mb-2">
              <SearchField value={query} onChange={setQuery} />
            </div>
          </div>
        </div>
      </div>
      <div className="block w-full overflow-x-auto">
        {/* Projects table */}
        <table className="items-center w-full bg-transparent border-collapse">
          <thead>
            <tr>
              <th
                className={`px-4 align-middle border border-solid py-3 text-sm xl:text-base uppercase border-l-0 border-r-0  font-semibold text-left ${
                  color === "light"
                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                    : "bg-red-800 text-red-300 border-red-700"
                }`}
              >
                Nombre
              </th>
              <th
                className={`px-4 align-middle border border-solid py-3 text-sm xl:text-base uppercase border-l-0 border-r-0  font-semibold text-left ${
                  color === "light"
                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                    : "bg-red-800 text-red-300 border-red-700"
                }`}
              >
                Correo
              </th>
              <th
                className={`px-4 align-middle border border-solid py-3 text-sm xl:text-base uppercase border-l-0 border-r-0  font-semibold text-left ${
                  color === "light"
                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                    : "bg-red-800 text-red-300 border-red-700"
                }`}
              >
                Cedula
              </th>
              <th
                className={`px-4 align-middle border border-solid py-3 text-sm xl:text-base uppercase border-l-0 border-r-0  font-semibold text-left ${
                  color === "light"
                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                    : "bg-red-800 text-red-300 border-red-700"
                }`}
              >
                Celular
              </th>
              <th
                className={`px-4 align-middle border border-solid py-3 text-sm xl:text-base uppercase border-l-0 border-r-0  font-semibold text-left ${
                  color === "light"
                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                    : "bg-red-800 text-red-300 border-red-700"
                }`}
              >
                Cantidad
              </th>
              <th
                className={`px-4 align-middle border border-solid py-3 text-sm xl:text-base uppercase border-l-0 border-r-0  font-semibold text-left ${
                  color === "light"
                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                    : "bg-red-800 text-red-300 border-red-700"
                }`}
              >
                Cuotas
              </th>
              <th
                className={`px-4 text-center max-w-[80px] align-middle border border-solid py-3 text-sm xl:text-base uppercase border-l-0 border-r-0  font-semibold ${
                  color === "light"
                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                    : "bg-red-800 text-red-300 border-red-700"
                }`}
              >
                opciones
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((p: any) => (
              <tr key={p.id}>
                <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-sm xl:text-base  p-2">
                  {p.name}
                </td>

                <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-sm xl:text-base  p-2">
                  <div className="flex items-center">{p.email}</div>
                </td>
                <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-sm xl:text-base  p-2">
                  <div className="flex items-center">{p.cedula}</div>
                </td>
                <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-sm xl:text-base  p-2">
                  <div className="flex items-center">{p.celular}</div>
                </td>
                <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-sm xl:text-base  p-2">
                  <div className="flex items-center">{p.cantidad}</div>
                </td>
                <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-sm xl:text-base  p-2">
                  <div className="flex items-center">{p.cuotas}</div>
                </td>
                <td className="border-t-0 max-w-[80px] px-4 align-middle border-l-0 border-r-0 text-sm xl:text-base  p-2">
                  <div className="w-full flex justify-center ">
                    {/* <button
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-0.5  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                      onClick={() => onClickUpdateSubCategory(p)}
                    >
                      <i className="fa fa-pencil" />
                    </button> */}

                    <button
                      className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-2 py-0.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                      onClick={() => onClickDeletePeticion(p.id)}
                    >
                      <i className="fa fa-trash" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="md:flex text-center w-full justify-between items-center mt-3">
        <div className="dataTables_info text-sm xl:text-base min-w-[100px]">
          Mostrando del {activePag.current * sort + 1} al{" "}
          {peticiones.length > (activePag.current + 1) * sort
            ? (activePag.current + 1) * sort
            : peticiones.length}{" "}
          de {peticiones.length} peticiones de préstamo
        </div>
        <div className="dataTables_paginate paging_simple_numbers overflow-x-auto">
          <Pagination
            size="sm"
            gutter
            variant="primary"
            activePag={activePag}
            onChangePagination={onChangePagination}
            limit={paggination.length}
          />
        </div>
      </div>
    </div>
  );
};

export default PeticionesPrestamosTable;
