import React from "react";
import icon from "assets/logo_icono_solo_azul.png";

import { Spinner } from "flowbite-react";
import "./index.css";

const LoaderFullPage = () => {
  return (
    <div className=" bg-blue-500 h-screen w-screen flex justify-center items-center">
      <div className="lds-circle">
        <Spinner aria-label="Extra large spinner example" size="xl" />
        <span className="pl-3 text-white text-xl">Cargando...</span>
      </div>
      {/* <img src={icon} className="img-preloader" alt="loader" /> */}
    </div>
  );
};

export default LoaderFullPage;
