import React from "react";
import { IProduct } from "utils/models/IProducts";

type IProductProps = {
  product: IProduct;
  onLoad: () => void; // Nuevo prop para manejar la carga de la imagen
};

const CardProductPdf = ({ product, onLoad }: IProductProps) => {
  const handleImageLoad = () => {
    onLoad(); // Llama al manejador de eventos para informar al componente padre que la imagen se ha cargado
  };

  return (
    <div className="flex w-full flex-col h-[284px] justify-between max-w-sm bg-white border border-gray-200 rounded-lg shadow">
      <img
        className="w-full shadow-xl h-[180px] min-h-[180px] object-contain rounded-t-lg"
        src={product.imagen}
        alt="imagen producto card"
        onLoad={handleImageLoad} // Agrega el evento onLoad para detectar cuando la imagen se ha cargado
      />
      <div className="px-4 pb-2 pt-0.5 flex flex-col justify-start h-full">
        <h3 className=" text-base font-bold tracking-tight text-gray-900 px-2">
          {product.nombreDescriptivo}
        </h3>
        <p className="mb-2 text-xs font-normal text-gray-700 px-2">
          {product.codigo}
        </p>
      </div>
    </div>
  );
};

export default CardProductPdf;
