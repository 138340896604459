import React, { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  fetchGetCartProductsAsync,
  getStatusCartProducts,
} from "../redux/cartProductSlice";
import LoaderBlue from "share/LoaderBlue";
import TableCarProducts from "../components/carrito/TableCarProducts";
import Pdfgenerate from "../pdf/Pdfgenerate";
const Carrito = () => {
  const dispatch = useAppDispatch();
  const statusCartProducts = useAppSelector(getStatusCartProducts);
  useEffect(() => {
    const getStatus = async () => {
      if (statusCartProducts === "idle") dispatch(fetchGetCartProductsAsync());

      console.log("-----------------", statusCartProducts);
    };

    getStatus();
  }, [dispatch, statusCartProducts]);

  return (
    <div className="h-full w-full flex flex-col  justify-start items-center min-h-[80vh]">
      <div className=" bg-gray-300 w-full text-white py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-2xl text-center  font-bold sm:text-4xl md:text-5xl lg:text-6xl">
            Productos seleccionados
          </h1>
          {/* <button
            type="button"
            onClick={() => dispatch(fetchGetCartProductsAsync())}
          >
            reload
          </button> */}
          {/* <p className="mt-4 text-xl">Descubre nuestras ofertas exclusivas.</p> */}
          {/* <div className="mt-8">
            <a
              href="#"
              className="inline-block bg-white text-blue-900 py-3 px-6 rounded-lg font-semibold shadow-md hover:bg-blue-100"
            >
              Ver más
            </a>
          </div> */}
        </div>
      </div>
      <div className="flex relative container max-w-screen-xl h-full ">
        <div className="w-full flex gap-4 p-4">
          {statusCartProducts === "success" ? (
            <TableCarProducts whatappActive={true}></TableCarProducts>
          ) : (
            <div className="h-full min-h-[500px] w-full flex justify-center items-center">
              <LoaderBlue></LoaderBlue>
            </div>
          )}
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default Carrito;
