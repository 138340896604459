import React, { useEffect, useState } from "react";
import imgGris from "assets/productos/img/fondo_gris.avif";
import { Card } from "flowbite-react";
import { cartProducts } from "modules/front/utils/cardProducts";
import { IProduct } from "utils/models/IProducts";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  fetchAddCartProductAsync,
  fetchGetCartProductsAsync,
  getCartProducts,
} from "modules/front/redux/cartProductSlice";
interface CardProductoProps {
  product: IProduct; // Tipo de dato para el producto
}

// Definición de la interfaz para el tipo de dato Producto
interface Producto {
  id: string | number;
  nombreDescriptivo: string;
  descripcion?: string;
  imagen: any;
}
const CardProducto: React.FC<CardProductoProps> = ({ product }) => {
  const [checkAdd, setCheckAdd] = useState(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  let cartProducts = useAppSelector((state) => getCartProducts(state, ""));

  const dispatch = useAppDispatch();
  useEffect(() => {
    const i = cartProducts.findIndex((obj) => obj.id === product.id);

    if (i !== -1) {
      setCheckAdd(true);
    }

    // eslint-disable-next-line no-use-before-define
  }, []);

  const addToCart = async () => {
    const isProductAdded = cartProducts.some(
      (cartProduct) => cartProduct.id === product.id
    );
    const updatedCartProducts = isProductAdded
      ? cartProducts.filter((cartProduct) => cartProduct.id !== product.id)
      : [...cartProducts, product];

    setCheckAdd(!isProductAdded);
    dispatch(fetchAddCartProductAsync(updatedCartProducts));
    dispatch(fetchGetCartProductsAsync());
  };
  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="flex w-full flex-col  justify-between max-w-sm hover:-translate-y-2 min-h-[300px]  bg-white border border-gray-200 rounded-lg shadow"
    >
      <img
        className="w-full shadow-xl h-[300px] sm:h-[200px] lg:h-[300px] xl:h-[280px] 2xl:[] object-containt lg:object-fill rounded-t-lg"
        src={product.imagen}
        alt="imagen producto card"
      />

      <div className="px-4 py-2">
        <h3 className=" text-xl font-bold tracking-tight text-gray-900 px-2">
          {product.nombreDescriptivo}
        </h3>
        <p
          style={{
            color: "black",
            transition: "color 5s ease",
            overflow: "hidden",
            transitionDelay: "500",
            whiteSpace: isHovered ? "normal" : "nowrap",
            textOverflow: isHovered ? "clip" : "ellipsis",
          }}
          className="mb-2 text-sm hover:whitespace-normal  hover:duration-500  font-normal text-gray-700 px-2"
        >
          {product.descripcion}
        </p>
        <div className="w-full flex justify-end p-2">
          {checkAdd === false ? (
            <button
              type="button"
              onClick={() => addToCart()}
              className="lg:text-lg xl:text-xl bg-vinotinto hover:bg-red-800 text-white font-bold py-1 px-6 rounded-full"
            >
              Cotizar
            </button>
          ) : (
            <button
              type="button"
              onClick={() => addToCart()}
              className="lg:text-lg xl:text-xl bg-azul-button hover:bg-blue-600 text-white font-bold py-1 px-6 rounded-full"
            >
              Quitar
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
export default CardProducto;
