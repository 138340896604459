import React from "react";

type IProductCardProps = {
  image: string;
  onClick?: () => void;
  active?: boolean;
  size?: "default" | "small";
};

const ProductCard: React.FC<IProductCardProps> = ({
  image,
  onClick,
  active,
  size,
}) => {
  return (
    <div
      className="flex flex-col items-center p-2"
      style={{ margin: "0 auto" }}
    >
      <div
        role="button"
        className={`card-bxx stack card ${active ? "active" : ""}${
          size === "small" ? "Product-small" : ""
        }`}
        style={{}}
        onClick={onClick}
      >
        {image !== "" && (
          <img src={image} alt="Imagen Producto" className="fs-0" />
        )}
      </div>
    </div>
  );
};

ProductCard.defaultProps = {
  active: false,
  onClick: undefined,
  size: "default",
};

export default ProductCard;
