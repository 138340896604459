import React, { useState } from "react";
import { Link } from "react-router-dom";
// image
import logo from "assets/productos/img/logo_transparente_azul_sintextos.png";
// import loginBackground from "assets/images/loginbg.png";
import { restorePassword } from "modules/dashboard/services/authService";
import Header from "modules/login/components/Header";
import Footer from "modules/login/components/Footer";

const RestorePassword: React.FC = () => {
  const [email, setEmail] = useState("");

  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    restorePassword(email);
  };
  return (
    <div className="bg-blue-500">
      {/* <app-auth-navbar></app-auth-navbar> */}
      <Header />
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          <div className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"></div>
          <div className="container mx-auto px-4 h-full">
            <div className="flex content-center items-center justify-center h-full">
              <div className="w-full lg:w-4/12">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0 bg-white">
                  <figure className="flex justify-center border-b-[3px] border-red-700 mt-5 px-4 lg:px-10">
                    <img className="h-[50px]" src={logo} alt="" />
                  </figure>
                  <div className="p-4">
                    <h4 className="text-center mb-4" translate="no">
                      Restablecer contraseña
                    </h4>
                    <p>
                      Enviaremos un correo con instrucciones sobre cómo
                      restablecer su contraseña
                    </p>
                    <form onSubmit={(e) => submitHandler(e)}>
                      <div className="form-group mb-3 ">
                        <label htmlFor="email" className="font-bold mr-2">
                          Correo electrónico
                        </label>
                        <input
                          id="email"
                          type="email"
                          className="form-control"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="text-center mb-3">
                        <button
                          type="submit"
                          className="bg-vinotinto text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                        >
                          Enviar
                        </button>
                      </div>
                      <div className="text-center">
                        <Link to="/login">
                          <small>Iniciar sesión</small>
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <router-outlet></router-outlet> */}
          <Footer absolute={true}></Footer>
        </section>
      </main>
    </div>
  );
};

export default RestorePassword;
