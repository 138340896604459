import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { getCacheValue, setCacheValue } from "utils/cache";
import {
  addBanner,
  getBannersHome,
  getBannersProducts,
  updateBannerHome,
  updateBannerProduct,
} from "../services/bannersService";

type IBanners = {
  id: string;
  url: string;
};
type IBannersProduct = {
  id: string;
  url: string;
  type: string;
};
interface AddBanner {
  url: string;
  type?: string;
}

type BannersState = {
  id: string;
  url: string;
  bannersHome: IBanners[];
  bannersProducts: IBannersProduct[];
  showModal: boolean;
  showModal2: boolean;
  formAction: "update" | "add";
  statusBannersHome: "idle" | "loading" | "success" | "fail";
  statusBannersProducts: "idle" | "loading" | "success" | "fail";
  statusModal: "idle" | "loading" | "success" | "fail";
};

const initialState: BannersState = {
  id: "",
  url: "",
  bannersHome: [],
  bannersProducts: [],
  formAction: "add",
  showModal: false,
  showModal2: false,
  statusBannersHome: "idle",
  statusBannersProducts: "idle",
  statusModal: "idle",
};

export const fetchAddBannerHomeAsync = createAsyncThunk<
  Promise<any>,
  AddBanner,
  { state: RootState }
>("auth/fetchAddBannerHomeAsync", async (props, { getState }) => {
  const {
    banners: { formAction, id },
  } = getState();
  console.log("accion", formAction);
  const res = await (formAction === "add"
    ? addBanner(props)
    : updateBannerHome({ ...props, id }));
  return res;
});
export const fetchUpdateBannerProductAsync = createAsyncThunk<
  Promise<any>,
  AddBanner,
  { state: RootState }
>("auth/fetchUpdateBannerProductAsync", async (props, { getState }) => {
  const {
    banners: { formAction, id },
  } = getState();
  console.log("accion", formAction);
  const res = await (formAction === "add"
    ? addBanner(props)
    : updateBannerProduct({ ...props, id }));
  return res;
});

export const fetchGetBannersHomeAsync = createAsyncThunk(
  "banner/fetchGetBannersHomeAsync",
  async (forceFetch: boolean | undefined): Promise<IBanners[]> => {
    const cacheValue = getCacheValue("bannersHome");
    if (cacheValue?.length > 0 && !forceFetch) {
      return cacheValue;
    }

    const response = await getBannersHome();
    console.log("response", response);
    setCacheValue("bannersHome", response, 5);
    return response;
  }
);

export const fetchGetBannersProductsAsync = createAsyncThunk(
  "banners/fetchGetBannersProductsAsync",
  async (forceFetch: boolean | undefined): Promise<IBannersProduct[]> => {
    const cacheValue = getCacheValue("bannersProduct");
    if (cacheValue?.length > 0 && !forceFetch) {
      return cacheValue;
    }

    const response = await getBannersProducts();
    setCacheValue("bannersProducts", response, 5);
    return response;
  }
);
export const bannersSlice = createSlice({
  name: "banners",
  initialState,
  reducers: {
    setFormAction: (
      state,
      action: PayloadAction<BannersState["formAction"]>
    ) => {
      state.formAction = action.payload;
    },
    setBannerToEdit: (state, action: PayloadAction<IBanners>) => {
      state.url = action.payload.url;
      state.id = action.payload.id;
      state.formAction = "update";
    },
    setBannerToEdit2: (state, action: PayloadAction<IBanners>) => {
      state.url = action.payload.url;
      state.id = action.payload.id;
      state.formAction = "update";
    },
    setShowModal: (state, action: PayloadAction<BannersState["showModal"]>) => {
      state.showModal = action.payload;
    },
    setShowModal2: (
      state,
      action: PayloadAction<BannersState["showModal"]>
    ) => {
      state.showModal2 = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetBannersProductsAsync.pending, (state) => {
        state.statusBannersProducts = "loading";
      })
      .addCase(
        fetchGetBannersProductsAsync.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.bannersProducts = action.payload;

          state.statusBannersProducts = "success";
        }
      )
      .addCase(fetchGetBannersProductsAsync.rejected, (state) => {
        state.statusBannersProducts = "fail";
      })

      .addCase(fetchGetBannersHomeAsync.pending, (state) => {
        state.statusBannersHome = "loading";
      })
      .addCase(
        fetchGetBannersHomeAsync.fulfilled,
        (state, action: PayloadAction<IBanners[]>) => {
          state.bannersHome = action.payload;

          state.statusBannersHome = "success";
        }
      )
      .addCase(fetchGetBannersHomeAsync.rejected, (state) => {
        state.statusBannersHome = "fail";
      })
      .addCase(fetchAddBannerHomeAsync.pending, (state) => {
        state.statusModal = "loading";
      })
      .addCase(fetchAddBannerHomeAsync.fulfilled, (state) => {
        state.statusModal = "success";
        state.showModal = false;
      })
      .addCase(fetchAddBannerHomeAsync.rejected, (state) => {
        state.statusModal = "fail";
      })
      .addCase(fetchUpdateBannerProductAsync.pending, (state) => {
        state.statusModal = "loading";
      })
      .addCase(fetchUpdateBannerProductAsync.fulfilled, (state) => {
        state.statusModal = "success";
        state.showModal2 = false;
      })
      .addCase(fetchUpdateBannerProductAsync.rejected, (state) => {
        state.statusModal = "fail";
      });
  },
});

// Export actions
export const { setFormAction, setBannerToEdit, setShowModal, setShowModal2 } =
  bannersSlice.actions;
// Definición de selectores
export const getBannersFields = (state: RootState) => {
  return {
    url: state.banners.url,
  };
};
export const getBanner = (state: RootState) => state.products.products;
export const getDataBannersHome = (state: RootState) => {
  return state.banners.bannersHome;
};
export const getDataBannersProducts = (state: RootState) => {
  return state.banners.bannersProducts;
};
export const getBannerImage = (state: RootState) => state.banners.url;
export const getShowModal = (state: RootState) => state.banners.showModal;
export const getShowModal2 = (state: RootState) => state.banners.showModal2;
export const getFormAction = (state: RootState) => state.banners.formAction;
export const getStatusBannersHome = (state: RootState) =>
  state.banners.statusBannersHome;
export const getStatusBannersProducts = (state: RootState) =>
  state.banners.statusBannersProducts;
export const getBannersHomeQuery = (state: RootState, query: string) => {
  if (query) {
    return state.banners.bannersHome.filter((d: any) => d.url !== "");
  } else {
    return state.banners.bannersHome;
  }
};
export const getBannersProductsQuery = (state: RootState, query: string) => {
  if (query) {
    return state.banners.bannersProducts.filter((d: any) => d.id === query);
  } else {
    return state.banners.bannersProducts;
  }
};
export const getStatusModal = (state: RootState) => state.banners.statusModal;

export default bannersSlice.reducer;
