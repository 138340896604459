import React from "react";
import { Link } from "react-router-dom";

const Error404 = () => {
  return (
    <div className="flex justify-center h-[80vh] p-middle">
      <div className="container h-full">
        <div className="flex justify-center h-full items-center">
          <div className="lg:w-1/2">
            <div className="form-input-content text-center error-page">
              <h1 className="error-text font-bold">404</h1>
              <h4>
                <i className="fas fa-exclamation-triangle text-warning" /> ¡La
                página que estabas buscando no se encuentra!
              </h4>
              <p>
                Es posible que hayas escrito mal la dirección o que la página se
                haya movido.
              </p>
              <div>
                <Link to="/home" className="btn btn-primary">
                  Volver al inicio
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error404;
