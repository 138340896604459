import React, { useEffect } from "react";
import ProductModal from "../components/products/productsModal";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  fetchGetProductsAsync,
  getStatusProducts,
} from "../redux/productSlice";
import ProductTable from "../components/products/ProductTable";
import Loader from "share/Loader";
import SubCategorysTable from "../components/products/SubCategorysTable";
import SubCategoryModal from "../components/products/SubCategoryModal";
import {
  fetchGetSubCategorysAsync,
  getStatusSubCategorys,
} from "../redux/subCategorySlice";
import PeticionesPrestamosTable from "../components/products/PeticionesPrestamosTable copy";
import {
  fetchGetPeticionesAsync,
  getStatusPeticiones,
} from "../redux/peticionesSlice";
import PeticionesModal from "../components/products/PeticionesModal";

const Products: React.FC = () => {
  const dispatch = useAppDispatch();
  const statusProducts = useAppSelector(getStatusProducts);
  const statusSubCategorys = useAppSelector(getStatusSubCategorys);
  const statusPeticiones = useAppSelector(getStatusPeticiones);
  useEffect(() => {
    const getStatus = async () => {
      if (statusProducts === "idle" || statusProducts === "loading")
        await dispatch(fetchGetProductsAsync());
      if (statusSubCategorys === "idle")
        await dispatch(fetchGetSubCategorysAsync());
      if (statusPeticiones === "idle" || statusProducts === "loading")
        await dispatch(fetchGetPeticionesAsync());
    };
    getStatus();
  }, []);

  return (
    <div className="flex flex-wrap h-full min-h[80vh]">
      <div className="w-full lg:w-full px-4 grid grid-cols-12 gap-4">
        <div className="col-span-12 relative card bg-white flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-black text-base lg:text-2xl  font-bold">
                Subir y editar productos
              </h6>
              <div className="">
                {/* <i className="fa-solid fa-ellipsis-vertical"></i> */}

                {statusProducts === "success" ? (
                  <ProductModal></ProductModal>
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          </div>
          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            {/* <app-table-product [color]="'light'"></app-table-product> */}
            {statusProducts === "success" ? (
              <ProductTable color={"light"}></ProductTable>
            ) : (
              <Loader />
            )}
          </div>
        </div>
        <div className="col-span-12 lg:col-span-5 relative bg-white flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex  justify-between">
              <h6 className="text-black text-base lg:text-2xl  font-bold">
                Crear y editar Subcategorías
              </h6>
              <div className="">
                {/* <i className="fa-solid fa-ellipsis-vertical"></i> */}

                {statusSubCategorys === "success" ? (
                  <SubCategoryModal></SubCategoryModal>
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          </div>
          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            {/* <app-table-product [color]="'light'"></app-table-product> */}
            {statusSubCategorys === "success" ? (
              <SubCategorysTable color={"light"}></SubCategorysTable>
            ) : (
              <Loader />
            )}
          </div>
        </div>
        <div className="col-span-12 lg:col-span-7 relative bg-white flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex  justify-between">
              <h6 className="text-black text-base lg:text-2xl font-bold">
                Revisar peticiones de préstamos
              </h6>
              <div className="">
                {statusPeticiones === "success" ? (
                  <PeticionesModal></PeticionesModal>
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          </div>
          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            {/* <app-table-product [color]="'light'"></app-table-product> */}
            {statusSubCategorys === "success" ? (
              <PeticionesPrestamosTable
                color={"light"}
              ></PeticionesPrestamosTable>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
