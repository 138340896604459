import React, { useEffect, useState } from "react";
import { Tabs } from "flowbite-react";

import ReloadProducts from "../components/selectProduct/ReloadProducts";
import GenerarPdf from "../components/selectProduct/GenerarPdf";

const SelectProducts = () => {
  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (label: any) => {
    // Aquí puedes ejecutar tu función personalizada
    console.log("Se hizo clic en la pestaña", label);

    // Actualiza el estado de la pestaña activa
    setActiveTab(label);
  };

  return (
    <div className="flex flex-wrap h-full min-h[80vh]">
      <div className="w-full lg:w-full px-4 grid grid-cols-12 gap-4">
        <div className="col-span-12 relative card bg-white flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-black text-base lg:text-2xl  font-bold">
                Seleccionar productos
              </h6>
            </div>
            <div>
              <Tabs
                className="overflow-x-auto"
                aria-label="Full width tabs"
                style="fullWidth"
                onActiveTabChange={(label) => handleTabChange(label)}
              >
                <Tabs.Item active title="Muebles"></Tabs.Item>
                <Tabs.Item title="Electrodomésticos"></Tabs.Item>
                <Tabs.Item title="Tecnología"></Tabs.Item>
                <Tabs.Item title="Deportes"></Tabs.Item>
                <Tabs.Item title="Generar PDF"></Tabs.Item>
              </Tabs>
              {/* <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                <ul className="flex flex-wrap -mb-px">
                  <li className="me-2">
                    <button
                      onClick={() => setActiveTab(0)}
                      className="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                    >
                      Muebles
                    </button>
                  </li>
                  <li className="me-2">
                    <button
                      onClick={() => setActiveTab(1)}
                      className="inline-block p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                      aria-current="page"
                    >
                      Dashboard
                    </button>
                  </li>
                  <li className="me-2">
                    <button
                      type="button"
                      onClick={() => setActiveTab(2)}
                      className="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                    >
                      Settings
                    </button>
                  </li>
                  <li className="me-2">
                    <button
                      type="button"
                      className="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                    >
                      Contacts
                    </button>
                  </li>
                </ul>
              </div> */}

              <div>
                {activeTab === 0 ? (
                  <div title="Muebles">
                    <ReloadProducts
                      subcategoria={"muebles"}
                      activeTab={activeTab}
                    />
                  </div>
                ) : (
                  <></>
                )}
                {activeTab === 1 ? (
                  <div title="Electrodomésticos">
                    <ReloadProducts
                      subcategoria={"electrodomesticos"}
                      activeTab={activeTab}
                    />
                  </div>
                ) : (
                  <></>
                )}
                {activeTab === 2 ? (
                  <div title="Tecnología">
                    <ReloadProducts
                      subcategoria={"tecnologia"}
                      activeTab={activeTab}
                    />
                  </div>
                ) : (
                  <></>
                )}
                {activeTab === 3 ? (
                  <div title="Deportes">
                    <ReloadProducts
                      subcategoria={"deportes"}
                      activeTab={activeTab}
                    />
                  </div>
                ) : (
                  <></>
                )}
                {activeTab === 4 ? (
                  <div title="Deportes">
                    <GenerarPdf />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SelectProducts;
