import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import {
  addProduct,
  getProductsForCategory,
  getProducts as getProductsService,
  updateProduct,
} from "../services/productService";
import { getCacheValue, setCacheValue } from "utils/cache";
import { IProduct, IAddProduct } from "utils/models/IProducts";

type ProductState = {
  id: string;
  imagen: string;
  codigo: string;
  categoria: string;
  subCategoria: string;
  productsFront: IProduct[];
  uidSubCategory: string;
  nombreDescriptivo: string;
  descripcion: string;
  precio: string;
  fechaCreacion?: string;
  showModal: boolean;

  formAction: "update" | "add";

  statusProducts: "idle" | "loading" | "success" | "fail";
  statusModal: "idle" | "loading" | "success" | "fail";
};

const initialState: ProductState = {
  id: "",
  imagen: "",
  codigo: "",
  categoria: "",
  subCategoria: "",
  nombreDescriptivo: "",

  uidSubCategory: "",
  descripcion: "",
  productsFront: [],
  precio: "",
  fechaCreacion: "",
  formAction: "add",
  showModal: false,
  statusProducts: "idle",
  statusModal: "idle",
};

export const fetchAddProductAsync = createAsyncThunk<
  Promise<any>,
  IAddProduct,
  { state: RootState }
>("auth/fetchAddProductFrontAsync", async (props, { getState }) => {
  const {
    productsFront: { formAction, id },
  } = getState();
  const res = await (formAction === "add"
    ? addProduct(props)
    : updateProduct({ ...props, id }));
  return res;
});

export const fetchGetProductsAsync = createAsyncThunk(
  "productsFront/fetchGetProductsAsync",
  async (forceFetch: boolean | undefined): Promise<IProduct[]> => {
    const cacheValue = getCacheValue("productsFront");
    if (cacheValue && !forceFetch) {
      return cacheValue;
    }

    const response = await getProductsService();
    setCacheValue("productsFront", response, 5);
    return response;
  }
);
export const fetchGetProductsForCategoryAsync = createAsyncThunk(
  "productsFront/fetchGetProductsForCategoryAsync",
  async ({ forceFetch, name }: any): Promise<IProduct[]> => {
    const cacheValue = getCacheValue("productsFront/" + name);
    if (cacheValue && !forceFetch) {
      return cacheValue;
    }

    const response = await getProductsForCategory(name);
    setCacheValue("productsFront/" + name, response, 5);
    return response;
  }
);

export const productsFrontSlice = createSlice({
  name: "productsFront",
  initialState,
  reducers: {
    setFormAction: (
      state,
      action: PayloadAction<ProductState["formAction"]>
    ) => {
      state.formAction = action.payload;
    },
    setProductToEdit: (state, action: PayloadAction<IProduct>) => {
      state.id = action.payload.id;
      state.imagen = action.payload.imagen;
      state.codigo = action.payload.codigo;
      state.categoria = action.payload.categoria;
      state.subCategoria = action.payload.subCategoria;
      state.uidSubCategory = action.payload.uidSubCategory;
      state.descripcion = action.payload.descripcion;
      state.precio = action.payload.precio;
      state.nombreDescriptivo = action.payload.nombreDescriptivo;
      state.formAction = "update";
    },
    setShowModal: (state, action: PayloadAction<ProductState["showModal"]>) => {
      state.showModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetProductsForCategoryAsync.pending, (state) => {
        state.statusProducts = "loading";
      })
      .addCase(
        fetchGetProductsForCategoryAsync.fulfilled,
        (state, action: PayloadAction<IProduct[]>) => {
          state.statusProducts = "success";
          state.productsFront = action.payload;
        }
      )
      .addCase(fetchGetProductsForCategoryAsync.rejected, (state) => {
        state.statusProducts = "fail";
      })
      .addCase(fetchGetProductsAsync.pending, (state) => {
        state.statusProducts = "loading";
      })
      .addCase(
        fetchGetProductsAsync.fulfilled,
        (state, action: PayloadAction<IProduct[]>) => {
          state.statusProducts = "success";
          state.productsFront = action.payload;
        }
      )
      .addCase(fetchGetProductsAsync.rejected, (state) => {
        state.statusProducts = "fail";
      })
      .addCase(fetchAddProductAsync.pending, (state) => {
        state.statusModal = "loading";
      })
      .addCase(fetchAddProductAsync.fulfilled, (state) => {
        state.statusModal = "success";
        state.showModal = false;
      })
      .addCase(fetchAddProductAsync.rejected, (state) => {
        state.statusModal = "fail";
      });
  },
});

// Export actions
export const { setFormAction, setProductToEdit, setShowModal } =
  productsFrontSlice.actions;
// Definición de selectores
export const getProductFields = (state: RootState) => {
  return {
    imagen: state.productsFront.imagen,
    codigo: state.productsFront.codigo,
    categoria: state.productsFront.categoria,
    subCategoria: state.productsFront.subCategoria,
    uidSubCategory: state.productsFront.uidSubCategory,
    descripcion: state.productsFront.descripcion,
    precio: state.productsFront.precio,
    nombreDescriptivo: state.productsFront.nombreDescriptivo,
  };
};
export const getProducts = (state: RootState) =>
  state.productsFront.productsFront;
export const getProductImage = (state: RootState) => state.productsFront.imagen;
export const getShowModal = (state: RootState) => state.productsFront.showModal;
export const getFormAction = (state: RootState) =>
  state.productsFront.formAction;
export const getStatusProducts = (state: RootState) =>
  state.productsFront.statusProducts;
export const getProductsQuery = (state: RootState, query: string) => {
  if (query) {
    return state.productsFront.productsFront.filter(
      (d: any) =>
        d.categoria.toLowerCase().includes(query) ||
        d.nombreDescriptivo.toLowerCase().includes(query) ||
        d.subCategoria.toLowerCase().includes(query)
    );
  } else {
    return state.productsFront.productsFront;
  }
};
export const getStatusModal = (state: RootState) =>
  state.productsFront.statusModal;

export default productsFrontSlice.reducer;
