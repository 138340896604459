import { PayloadAction, createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { login } from "../services/authService";
import { IUser } from "utils/models/IUser";
import { NavigateFunction } from "react-router-dom";

type AuthState = {
  user: IUser;
  status: "idle" | "loading" | "succeeded" | "failed";
  checkLogin: "empty" | "login" | "logout";
  errorMessage: string;
  successMessage: string;
};

const userInitialState: IUser = {
  id: "",
  email: "",
  name: "",
  lastName: "",
  celular: "",
  rol: "",
};

const initialState: AuthState = {
  user: userInitialState,
  status: "idle",
  checkLogin: "empty",
  errorMessage: "",
  successMessage: "",
};

type fetchLoginAsyncProps = {
  email: string;
  password: string;
  navigate: NavigateFunction;
};

export const fetchLoginAsync = createAsyncThunk(
  "auth/fetchLoginAsync",
  async ({
    email,
    password,
    navigate,
  }: fetchLoginAsyncProps): Promise<IUser> => {
    const response = await login({ email, password, navigate });
    return response;
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = userInitialState;
      state.successMessage = "";
      state.errorMessage = "";
      state.checkLogin = "logout";
    },
    loginConfirmedAction: (state, action: PayloadAction<AuthState["user"]>) => {
      state.user = action.payload;
      state.errorMessage = "";
      state.successMessage = "Login Successfully Completed";
      state.checkLogin = "login";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLoginAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchLoginAsync.fulfilled,
        (state, action: PayloadAction<IUser>) => {
          state.status = "succeeded";
          state.user = action.payload;
          state.successMessage = "Login Successfully Completed";
        }
      )
      .addCase(fetchLoginAsync.rejected, (state) => {
        state.status = "failed";
        state.successMessage = "";
      });
  },
});

// Export actions
export const { logout, loginConfirmedAction } = authSlice.actions;

// Definición de selectores
export const getStatusLogin = (state: RootState) => state.auth.status;
export const getCheckLogin = (state: RootState) => state.auth.checkLogin;
export const getUser = (state: RootState) => state.auth.user;
export const getErrorMessage = (state: RootState) => state.auth.errorMessage;
export const getSuccessMessage = (state: RootState) =>
  state.auth.successMessage;
export const isAuthenticated = (state: RootState) => state.auth.user.id !== "";

export default authSlice.reducer;
