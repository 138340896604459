import React, { useEffect, useState } from "react";
import CardProductHome from "../components/CardProductHome";
import banner1 from "assets/front/banners/1.png";
import banner2 from "assets/front/banners/2.png";
import banner3 from "assets/front/banners/3.png";
import banner4 from "assets/front/banners/4.png";
import banner5 from "assets/front/banners/5.png";
import { Carousel } from "flowbite-react";
import { Parallax } from "react-scroll-parallax";

import "./styles.css";
import {
  fetchGetProductsAsync,
  getProductsQuery,
  getStatusProducts,
} from "../redux/productSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  fetchGetCartProductsAsync,
  getStatusCartProducts,
} from "../redux/cartProductSlice";
import LoaderBlue from "share/LoaderBlue";
import {
  fetchGetBannersHomeAsync,
  getBannersHomeQuery,
  getStatusBannersHome,
} from "modules/dashboard/redux/bannersSlice";
const HomePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const statusProducts = useAppSelector(getStatusProducts);
  const statusCartProducts = useAppSelector(getStatusCartProducts);
  const statusBanners = useAppSelector(getStatusBannersHome);
  const banners = useAppSelector((state) => getBannersHomeQuery(state, ""));

  // const statusSubCategorys = useAppSelector(getStatusSubCategorys);

  const products = useAppSelector((state) => getProductsQuery(state, ""));
  useEffect(() => {
    if (statusProducts === "idle") dispatch(fetchGetProductsAsync(true));
    if (statusCartProducts === "idle") {
      dispatch(fetchGetCartProductsAsync());
    }
    if (statusBanners === "idle") {
      dispatch(fetchGetBannersHomeAsync(true));
    }
    // if (statusSubCategorys === "idle") dispatch(fetchGetSubCategorysAsync());
  }, [dispatch, statusCartProducts, statusProducts, statusBanners]);

  const productosTelefono = [
    {
      image:
        "https://media.cnn.com/api/v1/images/stellar/prod/galaxy-s23-product-image-lavender-backl30-hi.jpg?c=16x9&q=h_720,w_1280,c_fill",
      title: "Samsung S23",
      descripcion: "Móvil espectacular",
      id: "1",
    },
    {
      image:
        "https://store.storeimages.cdn-apple.com/1/as-images.apple.com/is/iphone-13-finish-select-202207-pink?wid=5120&hei=2880&fmt=p-jpg&qlt=80&.v=WGQwVDZoTWdLODlMWERUbVY5M013a1NCSGJEVklzV3dtVWxKME5oOWltbmVBZDlxVUJSaEtFRjFpR2NmRnBWNk1MbzhpenBBR1d2ZDlwL2trNE1mUkVBVmhCbWptUlRpTjBwNVdQK2hZN2NadE5KVnEwNEwyTzRZN1BWS2NxM2k=&traceId=1",
      title: "iPhone 13",
      descripcion: "Móvil espectacular",
      id: "2",
    },
    {
      image:
        "https://teknopolis.vtexassets.com/arquivos/ids/212002-800-450?v=638411222933970000&width=800&height=450&aspect=true",
      title: "Xiaomi Redmi",
      descripcion: "Móvil espectacular",
      id: "3",
    },
  ];
  const productosElectro = [
    {
      image:
        "https://electroluxco.vtexassets.com/arquivos/ids/162906/Robot_Vacuum_ERB10_Perspective_Electrolux_1000x1000.jpg?v=638151302320930000",
      title: "Aspiradora Robot",
      descripcion:
        "Inteligente, autónoma, eficiente, limpia sin esfuerzo. Tecnología avanzada, diseño compacto, ideal para hogares modernos. ¡Simplifica la limpieza diaria",
      id: "1",
    },
    {
      image:
        "https://www.alkomprar.com/medias/7704353446104-003-750Wx750H?context=bWFzdGVyfGltYWdlc3w2MDEyfGltYWdlL3dlYnB8YURNMkwyZzFPQzh4TkRNMU16VTROekUwTWpZNE5pODNOekEwTXpVek5EUTJNVEEwWHpBd00xODNOVEJYZURjMU1FZ3xmODM5MWIxNWViZDM4ZDY0Y2NkNzZiYmM0MDczMGNiZTc1OWRlZmY5OWE5ZjUwZjkxYWRmODc3YzgxYTg3OWY1",
      title: "Nevera Haceb",
      descripcion:
        "amplio espacio, eficiencia energética, tecnología de enfriamiento rápido, diseño elegante, perfecta para conservar tus alimentos frescos y organizados.",
      id: "2",
    },
    {
      image:
        "https://electrojaponesa.vteximg.com.br/arquivos/ids/157978-550-550/C-WA90H4400.jpg?v=636311426587130000",
      title: "Lavadora Samsung",
      descripcion:
        "alto rendimiento: carga grande, eficiencia energética, múltiples programas, tecnología de lavado avanzada, cuida tus prendas y ahorra tiempo y recursos.",
      id: "3",
    },
  ];
  const banners2 = [
    {
      image: banner1,
      title: "MUEBLES ",
      phrase: "Diseña el hogar de tus sueños",
    },
    {
      image: banner2,
      title: "TECNOLOGÍA ",
      phrase: "Explora un mundo de posibilidades",
    },
    {
      image: banner3,
      title: "ELECTRODOMÉSTICOS",
      phrase: "Haz tu vida más fácil",
    },
    {
      image: banner4,
      title: "DEPORTES ",
      phrase: "Alcanza tu mejor versión",
    },
    {
      image: banner5,
      title: "PRÉSTAMOS  ",
      phrase: "Convierte tus proyectos en realidad",
    },
  ];
  const [navigator, setNavigator] = useState(1);
  return (
    <>
      <div className="w-full lg:py-3 h-[50px]">
        {/* <SearchInput></SearchInput> */}
      </div>

      <Carousel slide={true} slideInterval={5000}>
        {banners.map((b) => (
          <div className="w-full contenedor xl:h-[400px] h-[120px] md:h-[200px] sm:h-[160px] lg:h-[270px] 2xl:h-[500px] 3xl:h-[500px] flex items-center overflow-hidden relative">
            <div className="text-white text-center w-full drop-shadow absolute z-10">
              {" "}
              {/* <h1 className=" font-semibold text-3xl lg:text-5xl">{b.title}</h1> */}
              {/* <p className=" font-semibold text-base lg:text-lg">{b.phrase}</p> */}
            </div>

            <img
              className="w-full h-full absolute z-0  object-center"
              src={b.url}
              alt="banners"
            />
          </div>
        ))}
      </Carousel>

      <Parallax
        translateY={[0, 0]}
        className="w-full grid my-2 grid-cols-12 px-4 lg:p-4  lg:my-5"
      >
        <div className="col-span-12 lg:col-span-3 py-2 px-4 sm:h-[200px] lg:h-[250px] flex productos-center justify-center">
          <ul className="flex flex-col gap-2 lg:gap-8">
            <li>
              <button
                onClick={() => setNavigator(1)}
                type="button"
                className={`${
                  navigator === 1
                    ? "lg:text-xl xl:text-2xl w-full bg-azul-button hover:bg-blue-700 text-white font-bold py-1 px-4 lg:px-8 xl:px-10 rounded-full"
                    : "lg:text-xl xl:text-2xl w-full bg-white hover:bg-azul-button text-gray-400 hover:text-white font-bold py-1 px-4 lg:px-8 xl:px-10 rounded-full"
                }`}
              >
                Muebles
              </button>
            </li>
            <li>
              <button
                onClick={() => setNavigator(2)}
                type="button"
                className={`${
                  navigator === 2
                    ? "lg:text-xl xl:text-2xl w-full bg-azul-button hover:bg-blue-700 text-white font-bold py-1 px-4 lg:px-8 xl:px-10 rounded-full"
                    : "lg:text-xl xl:text-2xl w-full bg-white hover:bg-azul-button text-gray-400 hover:text-white font-bold py-1 px-4 lg:px-8 xl:px-10 rounded-full"
                }`}
              >
                Electrodomésticos
              </button>
            </li>
            <li>
              <button
                onClick={() => setNavigator(3)}
                type="button"
                className={`${
                  navigator === 3
                    ? "lg:text-xl xl:text-2xl w-full bg-azul-button hover:bg-blue-700 text-white font-bold py-1 px-4 lg:px-8 xl:px-10 rounded-full"
                    : "lg:text-xl xl:text-2xl w-full bg-white hover:bg-azul-button text-gray-400 hover:text-white font-bold py-1 px-4 lg:px-8 xl:px-10 rounded-full"
                }`}
              >
                Tecnología
              </button>
            </li>

            <li>
              <button
                onClick={() => setNavigator(4)}
                type="button"
                className={`${
                  navigator === 4
                    ? "lg:text-xl xl:text-2xl w-full bg-azul-button hover:bg-blue-700 text-white font-bold py-1 px-4 lg:px-8 xl:px-10 rounded-full"
                    : "lg:text-xl xl:text-2xl w-full bg-white hover:bg-azul-button text-gray-400 hover:text-white font-bold py-1 px-4 lg:px-8 xl:px-10 rounded-full"
                }`}
              >
                Deportes
              </button>
            </li>
          </ul>
        </div>
        {statusProducts === "success" ? (
          <div className="col-span-12 lg:col-span-9  ">
            {/* Aquí deberías mapear los productos y renderizar el componente `app-card-producto-home` */}
            {navigator === 1 ? (
              <div className=" grid gap-3 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 grid-rows-1">
                {products
                  .filter((producto) => producto.categoria === "muebles")
                  .slice(0, 3)
                  .map((producto) => {
                    return (
                      // Aquí va tu JSX para renderizar el producto
                      <div className="col-span-1  px-6 sm:px-2 lg:px-1">
                        <CardProductHome key={producto.id} product={producto} />
                      </div>
                    );
                    // Opcional: si no deseas renderizar nada para los productos que no son de la categoría "muebles"
                  })}{" "}
              </div>
            ) : (
              <></>
            )}
            {navigator === 2 ? (
              <div className=" grid gap-3 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 grid-rows-1">
                {products
                  .filter(
                    (producto) => producto.categoria === "electrodomesticos"
                  )
                  .slice(0, 3)
                  .map((producto) => {
                    return (
                      // Aquí va tu JSX para renderizar el producto
                      <div className="col-span-1  px-6 sm:px-2 lg:px-1">
                        <CardProductHome key={producto.id} product={producto} />
                      </div>
                    );
                    // Opcional: si no deseas renderizar nada para los productos que no son de la categoría "muebles"
                  })}{" "}
              </div>
            ) : (
              <></>
            )}
            {navigator === 3 ? (
              <div className=" grid gap-3 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 grid-rows-1">
                {products
                  .filter((producto) => producto.categoria === "tecnologia")
                  .slice(0, 3)
                  .map((producto) => {
                    return (
                      // Aquí va tu JSX para renderizar el producto

                      <div className="col-span-1  px-6 sm:px-2 lg:px-1">
                        <CardProductHome key={producto.id} product={producto} />
                      </div>
                    );

                    // Opcional: si no deseas renderizar nada para los productos que no son de la categoría "muebles"
                  })}{" "}
              </div>
            ) : (
              <></>
            )}

            {navigator === 4 ? (
              <div className=" grid gap-3 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 grid-rows-1">
                {products
                  .filter((producto) => producto.categoria === "deportes")
                  .slice(0, 3)
                  .map((producto) => {
                    return (
                      // Aquí va tu JSX para renderizar el producto

                      <div className="col-span-1  px-6 sm:px-2 lg:px-1">
                        <CardProductHome key={producto.id} product={producto} />
                      </div>
                    );

                    // Opcional: si no deseas renderizar nada para los productos que no son de la categoría "muebles"
                  })}{" "}
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <div className="col-span-12 lg:col-span-9 min-h-[300px] flex justify-center items-center  ">
            <LoaderBlue />
          </div>
        )}
      </Parallax>
      <Parallax className="w-full  flex flex-col lg:flex-col-reverse mb-5 mt-20">
        <div className=" md:col-span-1 p-4 md:flex flex-col justify-center lg:pl-16 xl:pl-32">
          <div className="w-full  ">
            <h2 className="text-3xl lg:text-[40px] xl:text-5xl text-center">
              Préstamos
            </h2>
            <p className="mt-3 text-center  text-xs lg:text-sm xl:text-base  p-2 lg:px-10 xl:px-16">
              <strong>El respaldo financiero que necesitas está aquí</strong>{" "}
              para ayudarte a construir tu futuro o facilitarte tu día a día.
              ¡Tenemos excelentes tasas de interés! Escríbenos y pronto nos
              comunicaremos contigo.
              {/* <strong>diseños modernos y funcionales.</strong> */}
            </p>
            <div className="w-full flex justify-center">
              <a
                type="button"
                href={"/prestamos"}
                className="text-sm font-light lg:text-base mt-2 bg-azul-button hover:bg-blue-700 text-white py-2 px-4 lg:px-10"
              >
                Solicitar préstamo
              </a>
            </div>
          </div>
        </div>
      </Parallax>
      <div className="w-full bg-segundario flex flex-col lg:flex-col-reverse">
        <div className="grid grid-cols-2">
          <div className="col-span-2 md:col-span-1 p-4 md:flex flex-col justify-center lg:pl-16 xl:pl-32">
            <div className="w-full max-w-[350px] p-2 lg:max-w-[400px] xl:max-w-[450px]">
              <h2 className="text-3xl lg:text-[40px] xl:text-5xl">
                Muebles hechos <br />
                por santandereanos
              </h2>
              <p className="mt-7 text-xs lg:text-sm xl:text-base w-[400px]:">
                Sumérgete en el hogar de tus sueños y descubre cómo nuestros
                muebles pueden{" "}
                <strong>
                  transformar tu hogar en un espacio de confort y estilo.
                </strong>{" "}
                Desde elegantes mesas hasta acogedores sofás, fusionamos la
                belleza natural de la madera con{" "}
                <strong>diseños modernos y funcionales.</strong>
              </p>
            </div>
          </div>
          <div className="col-span-2 md:col-span-1 p-4 lg:p-16 lg:pl-0 flex-col justify-center productos-center">
            <img
              src="https://silvinac.com.ar/wp-content/uploads/2023/06/Silla-de-Petiribi-Bali-SILVINA-C-MUEBLERIA-DECORACION.jpg"
              alt=""
            />
          </div>
        </div>
        <div className="w-full grid grid-cols-12 px-4 py-16 lg:py-40">
          <div className="col-span-12 lg:col-span-4 px-2 flex productos-start lg:justify-center">
            <div>
              <h2 className="text-3xl lg:text-5xl">Móviles</h2>
              <button className="text-sm font-light lg:text-base mt-2 bg-azul-button hover:bg-blue-700 text-white py-2 px-4 lg:px-10">
                VER PRODUCTOS
              </button>
            </div>
          </div>
          <div className=" lg:mt-0 col-span-12 lg:col-span-8 grid gap-3 grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 grid-rows-1">
            {productosTelefono.map((producto, index) => (
              <Parallax
                key={index}
                className="col-span-1 mt-3  row-span-1 px-6 sm:px-2 lg:px-1"
              >
                <div className="flex w-full lg:w-[200px] xl:w-[300px] flex-col justify-between">
                  <img
                    className="w-full shadow-xl h-[500px] sm:h-[450px] lg:h-[400px] xl:h-[500px] object-cover"
                    src={producto.image}
                    alt="imagen producto card"
                  />
                  <h3 className="mt-2 lg:text-xl xl:text-2xl font-light">
                    {producto.title}
                  </h3>
                  <p className="mt-1 text-xs lg:text-sm xl:text-base font-light">
                    {producto.descripcion}
                  </p>
                  {/* <div className="w-full flex justify-end p-2">
                <button className="lg:text-lg xl:text-xl bg-vinotinto hover:bg-red-800 text-white font-bold py-1 px-6 rounded-full">
                  Cotizar
                </button>
              </div> */}
                </div>
              </Parallax>
            ))}
          </div>
        </div>
      </div>

      <div className="w-full bg-segundario grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-x-10 2xl:gap-x-20 p-4 lg:p-16 xl:px-32">
        <h2 className="col-span-1 sm:col-span-2 lg:col-span-3 mb-7 text-3xl lg:text-[40px] xl:text-5xl">
          Electrodomésticos
        </h2>
        {productosElectro.map((producto, index) => (
          <div
            key={index}
            className="col-span-1 row-span-1 px-6 sm:px-2 lg:px-1 flex flex-col productos-center"
          >
            <div className="flex w-full flex-col justify-between">
              <img
                className="w-full shadow-xl h-[100%]  2xl:h-[100%]  object-contain"
                src={producto.image}
                alt="imagen producto card"
              />
              <h3 className="mt-2 lg:text-xl xl:text-2xl font-light">
                {producto.title}
              </h3>
              <p className="mt-1 text-xs lg:text-sm xl:text-base font-light">
                {producto.descripcion}
              </p>
              {/* <div className="w-full flex justify-end p-2">
                  <button className="lg:text-lg xl:text-xl bg-vinotinto hover:bg-red-800 text-white font-bold py-1 px-6 rounded-full">
                    Cotizar
                  </button>
                </div> */}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default HomePage;
