import React, { useEffect, useRef, useState } from "react";
import PdfHeader from "./pdfHeader";
import Footer from "../components/footer";
import { IProduct } from "utils/models/IProducts";
import { number } from "yup";
import CardProductPdf from "./CardProductPdf";
import jsPDF from "jspdf";
import Loader from "share/Loader";
import { useAppSelector } from "app/hooks";
import { getCartProducts } from "../redux/cartProductSlice";

import { Button, Checkbox, Label, TextInput } from "flowbite-react";
import image from "assets/productos/img/fondo_gris.avif";
import axios from "axios";
import html2canvas from "html2canvas";
import { HiMail } from "react-icons/hi";
type IProductPDFProps = {
  products: IProduct[];
  whatsappActive?: boolean;
  user: {
    name: string;
    email: string;
    celular: string | number;
  };
};
interface FormData {
  name: string;
  email: string;
  celular: string;
}

const Pdfgenerate = ({ products, user, whatsappActive }: IProductPDFProps) => {
  const pagina1Ref = useRef<HTMLDivElement>(null);
  const [pagesPDf, setPagesPDf] = useState<[IProduct[]] | any>([]);
  const [imagesLoaded, setImagesLoaded] = useState(false); // Nuevo estado para controlar si las imágenes se han cargado
  const [activatePdf, setActivatePdf] = useState(false); // Nuevo estado para controlar si las imágenes se han cargado

  const [query, setQuery] = useState("");
  const products2 = useAppSelector((state) => getCartProducts(state, query));
  const [loader, setLoader] = useState(false);
  const [active, setActive] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    celular: "",
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Verificar que los campos obligatorios no estén vacíos
    if (!formData.name || !formData.email) {
      alert("Los campos de nombre y email son obligatorios");
      return;
    }
    generatePDF();
    // Ejecutar la función aquí
  };

  const calculatePdfProduct = () => {
    let pages: any[] = [];
    let countSections = 0;
    let countPages = 0;
    const heightSectionAbsoluted = 300;
    let heightSection = 900;
    // const maxHeight = 900;
    for (let i = 0; i < products.length; i++) {
      if (!pages[countPages]) {
        pages[countPages] = []; // Si el arreglo en countPages no está inicializado, inicialízalo como un arreglo vacío
      }
      if (i !== 0 && i % 3 === 0) {
        countSections = countSections + 1;

        heightSection = heightSection - heightSectionAbsoluted;
        if (heightSection === 0) {
          heightSection = 900;
          countPages = countPages + 1;
          countSections = 0;
          if (!pages[countPages]) {
            pages[countPages] = []; // Si el arreglo en countPages no está inicializado, inicialízalo como un arreglo vacío
          }
          pages[countPages].push(products2[i]);
        } else {
          pages[countPages].push(products2[i]);
        }
      } else {
        pages[countPages].push(products2[i]);
      }
    }
    setPagesPDf(pages);
  };
  useEffect(() => {
    calculatePdfProduct();
  }, []);

  const generatePDF = async () => {
    setLoader(true);
    if (!imagesLoaded) {
      console.log("Esperando a que se carguen las imágenes...");
      return;
    }

    if (pagina1Ref.current) {
      const pdf = new jsPDF("p", "px", [816, 1345]);
      const content = pagina1Ref.current.innerHTML;
      const name = user.name;

      await new Promise<void>((resolve, reject) => {
        pdf.html(content, {
          callback: function () {
            pdf.save("Productos_credipopular" + name);
            resolve();
          },
          autoPaging: "text",
          margin: [0, 0, 0, 0],
          html2canvas: {
            scale: 1,
          },
        });
      });
    }
    setLoader(false);
  };
  // const generatePDF = async () => {
  //   if (!imagesLoaded) {
  //     console.log("Esperando a que se carguen las imágenes...");
  //     return;
  //   }
  //   setLoader(true);
  //   calculatePdfProduct();
  //   let numPages = pagesPDf.length;
  //   // Obtener el contenido de cada página como una imagen de canvas
  //   const canvasPages = await Promise.all(
  //     Array.from(Array(numPages).keys()).map(async (pageIndex) => {
  //       const page = document.getElementById(`page${pageIndex + 1}`);
  //       if (page instanceof HTMLElement) {
  //         const canvas = await html2canvas(page, { scale: 2 });
  //         return canvas;
  //       } else {
  //         console.error(`No se encontró el elemento #page${pageIndex + 1}`);
  //         return null;
  //       }
  //     })
  //   );

  //   // Crear un nuevo PDF
  //   const pdf = new jsPDF("p", "px", [816 * 2, 1344 * 2]);

  //   // Agregar cada página al PDF
  //   canvasPages.forEach((canvas, index) => {
  //     if (index > 0) {
  //       pdf.addPage();
  //     }
  //     if (canvas) {
  //       pdf.addImage(
  //         canvas.toDataURL("image/png"),
  //         "PNG",
  //         0,
  //         0,
  //         canvas.width,
  //         canvas.height
  //       );
  //     }
  //   });

  //   // Imprimir el número total de páginas en el PDF
  //   pdf.text(`Total de páginas: ${numPages}`, 10, 10);

  //   // Guardar el PDF
  //   pdf.save("documento.pdf");

  //   setLoader(false);
  // };
  const handleChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleImageLoad = () => {
    setImagesLoaded(true);
  };
  return (
    <div className="relative w-full">
      <div className=" flex justify-end">
        {!activatePdf ? (
          <button
            type="button"
            onClick={() => setActivatePdf(true)}
            className="lg:text-lg xl:text-xl bg-vinotinto hover:bg-red-800 text-white font-bold py-1 px-6 rounded-full"
          >
            {!loader ? <p>Descargar PDF</p> : <Loader />}
          </button>
        ) : (
          <form
            onSubmit={handleSubmit}
            className="grid  grid-col-1 md:grid-cols-2 gap-4 w-full mt-4"
          >
            <div className="col-span-1">
              <div className="mb-2 block">
                <Label htmlFor="name" value="Nombre completo" />
              </div>
              <TextInput
                id="name"
                type="text"
                required
                name="name"
                shadow
                value={formData.name}
                onChange={handleChange}
              />
            </div>

            <div className="col-span-1">
              <div className="mb-2 block">
                <Label htmlFor="cedula" value="Celular" />
              </div>
              <TextInput
                id="celular"
                name="celular"
                type="number"
                required
                shadow
                value={formData.celular}
                onChange={handleChange}
              />
            </div>
            <div className="col-span-1 ">
              <div className="mb-2 block">
                <Label htmlFor="email4" value="Correo" />
              </div>
              <TextInput
                id="email4"
                name="email"
                type="email"
                icon={HiMail}
                value={formData.email}
                placeholder="ejemplo@gmail.com"
                onChange={handleChange}
                required
              />
            </div>

            <div className="col-span-2 flex justify-end">
              <button
                className="lg:text-lg xl:text-xl bg-vinotinto hover:bg-red-800 text-white font-bold py-1 px-6 rounded-full"
                type="submit"
              >
                {!loader ? <p>Descargar PDF</p> : <Loader />}
              </button>
            </div>
          </form>
        )}
      </div>

      <div id="pagina1" ref={pagina1Ref} className="fixed -z-50  ">
        {pagesPDf.map((pagesData: IProduct[], index: any) => (
          <div
            key={index}
            id={`page${index + 1}`}
            className="w-[816px] h-[1344.85px] relative  bg-white"
          >
            <div className="w-[816px] p-0 ">
              <PdfHeader user={formData}></PdfHeader>
            </div>

            <div className="w-full absolute bottom-0  left-0">
              <Footer></Footer>
            </div>
            <div className="p-4 grid grid-cols-3 gap-4">
              {/* <img src={image} alt="" /> */}
              {pagesData.map((product, index) => (
                <div key={product.id}>
                  <CardProductPdf product={product} onLoad={handleImageLoad} />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Pdfgenerate;
