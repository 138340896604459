import React, { useState } from "react";

import { useAppSelector } from "app/hooks";
import icon from "assets/logo_icono_solo_azul.png";
import { getSubCategorysQuery } from "modules/front/redux/subCategorySlice";

interface Props {
  cambiarSubcategoria: (nuevaSubcategoria: string) => void;

  subcategoria: string;
}

const SideBar = ({ cambiarSubcategoria, subcategoria }: Props) => {
  const subCategorys = useAppSelector((state) =>
    getSubCategorysQuery(state, "")
  );
  const [collapseShow2, setCollapseShow] = useState(false);
  const ruta = window.location.pathname;
  const valorDespuesDeBarra = ruta.split("/")[1];

  const handleClick = (name: any) => {
    cambiarSubcategoria(name.toLowerCase());
    console.log("click");
  };
  return (
    <div>
      <button
        className=" absolute block top-1 left-1 cursor-pointer text-black  opacity-50 z-20 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
        type="button"
        onClick={() => setCollapseShow(!collapseShow2)}
      >
        <i className="fa-solid fa-bars"></i>
      </button>
      {/* <nav movile */}
      <div
        onClick={() => setCollapseShow(false)}
        className={`${
          collapseShow2
            ? "fixed w-screen lg:w-screen min-h-screen h-full flex flex-col gap-2 py-5 pl-5 text-base left-0 top-0  bg-black opacity-30 z-50"
            : "hidden"
        }`}
      ></div>
      <div
        className={`${
          collapseShow2
            ? "fixed md:hidden flex flex-col gap-2 py-5 px-5 text-base left-0 top-0 h-screen w-full bg-white z-50 max-w-[200px] "
            : "hidden"
        }`}
      >
        <nav>
          <ul className="">
            <button
              type="button"
              style={{ textTransform: "capitalize" }}
              onClick={() => handleClick("")}
              className=" text-sm md:text-sm lg:text-xl mb-4 xl:text-2xl w-full bg-azul-button hover:bg-blue-700 text-white font-bold py-1 px-4 lg:px-8 xl:px-10 rounded-full"
            >
              {valorDespuesDeBarra}
            </button>
            {subCategorys.map((sc) => (
              <button
                type="button"
                className={`${
                  subcategoria.charAt(0).toUpperCase() +
                    subcategoria.slice(1).toLowerCase() ===
                  sc.name
                    ? " text-sm md:text-sm lg:text-xl xl:text-2xl w-full bg-azul-button hover:bg-blue-700 text-white font-bold py-1 px-4 lg:px-8 xl:px-10 rounded-full"
                    : " text-sm md:text-sm lg:text-xl xl:text-2xl w-full bg-white hover:bg-azul-button text-gray-400 hover:text-white font-bold py-1 px-4 lg:px-8 xl:px-10 rounded-full"
                }`}
                key={sc.id}
                onClick={() => {
                  handleClick(sc.name);
                  setCollapseShow(false);
                }}
              >
                {sc.name}
              </button>
            ))}
          </ul>
        </nav>
      </div>
      <div className="hidden md:block md:relative w-full  bg-white p-4 h-full min-w-[150px]">
        <nav>
          <ul>
            <button
              type="button"
              style={{ textTransform: "capitalize" }}
              onClick={() => handleClick("")}
              className="text-sm md:text-sm lg:text-xl mb-4 xl:text-2xl w-full bg-azul-button hover:bg-blue-700 text-white font-bold py-1 px-4 lg:px-8 xl:px-10 rounded-full"
            >
              {valorDespuesDeBarra}
            </button>
            {subCategorys.map((sc) => (
              <li
                className={`${
                  subcategoria.charAt(0).toUpperCase() +
                    subcategoria.slice(1).toLowerCase() ===
                  sc.name
                    ? "text-sm md:text-sm lg:text-xl my-2 xl:text-2xl w-full bg-azul-button hover:bg-blue-700 text-white font-bold py-1 px-4 lg:px-8 xl:px-10 rounded-full"
                    : " text-sm md:text-sm lg:text-xl   my-2  xl:text-2xl w-full bg-white hover:bg-azul-button text-gray-400 hover:text-white font-bold py-1 px-4 lg:px-8 xl:px-10 rounded-full"
                }`}
                key={sc.id}
                onClick={() => handleClick(sc.name)}
              >
                {sc.name}
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
  // return (
  //   <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4">
  //     <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
  //       {/* Toggler */}
  //       <button
  //         className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
  //         type="button"
  //         onClick={() => toggleCollapseShow("bg-white m-2 py-3 px-6")}
  //       >
  //         <i className="fas fa-bars"></i>
  //       </button>
  //       {/* Brand */}
  //       <a
  //         href="/"
  //         className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold w-full px-0"
  //       >
  //         <figure className="flex justify-center border-b-[3px] border-red-700 px-4 lg:px-6">
  //           <img className="h-[50px]" src={logo} alt="" />
  //         </figure>
  //       </a>
  //       {/* User */}

  //       <div
  //         className={`md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-2 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded ${collapseShow}`}
  //       >
  //         {/* Collapse header */}
  //         <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
  //           <div className="flex flex-wrap">
  //             <div className="w-6/12">
  //               <a
  //                 href="/"
  //                 className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
  //               >
  //                 <figure className="flex justify-center border-b-[3px] border-red-700 mt-5 px-4 lg:px-10">
  //                   <img className="h-[50px]" src={logo2} alt="" />
  //                 </figure>
  //               </a>
  //             </div>
  //             <div className="w-6/12 flex justify-end">
  //               <button
  //                 type="button"
  //                 className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
  //                 onClick={() => toggleCollapseShow("hidden")}
  //               >
  //                 <i className="fas fa-times"></i>
  //               </button>
  //             </div>
  //           </div>
  //         </div>
  //         {/* Form */}
  //         <form className="mt-6 mb-4 md:hidden">
  //           <div className="mb-3 pt-0">
  //             <input
  //               type="text"
  //               placeholder="Search"
  //               className="border-0 px-3 py-2 h-12 border-solid border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
  //             />
  //           </div>
  //         </form>
  //         {/* Divider */}
  //         {/* Heading */}
  //         <h6 className="md:min-w-full px-4 text-cafe text-xs md:text-base lg:text-lg uppercase font-bold block pt-1 no-underline">
  //           Admin
  //         </h6>
  //         {/* Navigation */}
  //         <ul className="md:flex-col px-4 pl-7 md:min-w-full flex flex-col list-none">
  //           {rutas.dashboard.children.map((ruta: any, index: any) => (
  //             <li key={index} className="text-">
  //               <Link
  //                 to={ruta.url}
  //                 className={`text-xs lg:text-base uppercase py-2 font-bold block ${
  //                   !collapseShow && "text-black bg-white rounded-l-lg pl-4"
  //                 } ${
  //                   collapseShow &&
  //                   "text-white hover:text-azul-principal hover:bg-white hover:rounded-l-lg hover:pl-4"
  //                 }`}
  //               >
  //                 <i className={`${ruta.flatIcon} mr-2 text-sm`}></i>
  //                 {ruta.title}
  //               </Link>
  //             </li>
  //           ))}

  //           {/* <li className="items-center">
  //             <a href="/" className="text-xs uppercase py-3 font-bold block">
  //               <i className="fas fa-tools mr-2 text-sm"></i> Settings
  //             </a>
  //           </li>
  //           <li className="items-center">
  //             <a href="/" className="text-xs uppercase py-3 font-bold block">
  //               <i className="fas fa-table mr-2 text-sm"></i> Tables
  //             </a>
  //           </li>
  //           <li className="items-center">
  //             <a href="/" className="text-xs uppercase py-3 font-bold block">
  //               <i className="fas fa-map-marked mr-2 text-sm"></i> Maps
  //             </a>
  //           </li> */}
  //         </ul>
  //       </div>
  //       <button
  //         className=""
  //         type="button"
  //         onClick={() => handleLogout(navigate, dispatch)}
  //       >
  //         {" "}
  //         Salir
  //       </button>
  //     </div>
  //   </nav>
  // );
};

export default SideBar;
