import dayjs from "dayjs";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { signIn } from "./authService";
import { IAddUser, IUser } from "utils/models/IUser";
import {
  showError,
  showErrorForGetData,
  showErrorMessage,
  showSuccess,
  showSuccessMessage,
} from "share/serviceMessages";
import { db } from "../../../firebase";

const obj = "Distribuidor";

export const addUser = async ({
  name,
  lastName,
  email,
  password,
  celular,
}: IAddUser) => {
  try {
    const createdAt = serverTimestamp();

    const uid = await signIn(email, password);

    await setDoc(doc(db, "Users", uid), {
      email,
      name,
      lastName,
      celular,
      rol: "user",
      fechaCreacion: createdAt,
    });
    showSuccessMessage({ obj, action: "add" });
    return uid;
  } catch (error) {
    showErrorMessage({ obj, error, action: "add" });
    return Promise.reject();
  }
};

interface IUpdateUserProps extends IAddUser {
  id: string;
}

export const updateUser = async (payload: IUpdateUserProps) => {
  try {
    const UserRef = doc(db, "Users", payload.id);
    const UserSnap = await getDoc(UserRef);

    if (UserSnap.exists()) {
      const UserData = UserSnap.data() as IUser;

      await updateDoc(UserRef, {
        name: payload.name,
        email: payload.email,
        lastName: payload.lastName,
        celular: payload.celular,
      });

      showSuccessMessage({ obj, action: "update" });
    } else {
      throw new Error("No se encontró el distribuidor");
    }
  } catch (error) {
    showErrorMessage({ obj, error, action: "update" });
    return Promise.reject();
  }
};

export const getUsers = async () => {
  try {
    const querySnapshot = await getDocs(
      query(collection(db, "Users"), orderBy("fechaCreacion"))
    );
    const res = Promise.all(
      querySnapshot.docs.map(async (d) => {
        const data = d.data();
        return {
          id: d.id,
          ...data,
          fechaCreacion: dayjs(data.fechaCreacion.toDate()).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
        } as IUser;
      })
    );
    console.log(res);
    return res;
  } catch (error) {
    showErrorForGetData({ obj, error });
    return Promise.reject();
  }
};

export const deleteUser = async (id: string) => {
  try {
    await deleteDoc(doc(db, "Users", id));

    showSuccessMessage({ obj, action: "delete" });
  } catch (error) {
    showErrorMessage({ obj, error, action: "delete" });
    return Promise.reject();
  }
};
