import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import {
  addPeticion,
  getPeticiones as getPeticionesService,
} from "../services/peticioensService";

import { getCacheValue, setCacheValue } from "utils/cache";
interface IPeticion {
  id?: string;
  name: string;
  cedula: string | number;
  celular: string | number;
  cantidad: string | number;
  cuotas: string | number;
  email: string;
  fechaCreacion?: string;
}
interface AddIPeticion {
  name: string;
  cedula: string | number;
  celular: string | number;
  cantidad: string | number;
  cuotas: string | number;
  email: string;
}
type PeticioneState = {
  id: string;
  name: string;
  celular: string;
  cedula: string;
  cantidad: string | number;
  cuotas: string | number;
  email: string;
  peticiones: IPeticion[];
  showModal: boolean;
  formAction: "update" | "add";

  statusPeticiones: "idle" | "loading" | "success" | "fail";
  statusModal: "idle" | "loading" | "success" | "fail";
};

const initialState: PeticioneState = {
  id: "",
  name: "",
  celular: "",
  cedula: "",
  cantidad: "",
  cuotas: "",
  email: "",
  peticiones: [],
  formAction: "add",
  showModal: false,
  statusPeticiones: "idle",
  statusModal: "idle",
};

export const fetchAddPeticioneAsync = createAsyncThunk<
  Promise<any>,
  AddIPeticion,
  { state: RootState }
>("auth/fetchAddPeticioneAsync", async (props, { getState }) => {
  const {
    peticiones: { formAction, id },
  } = getState();
  const res = await (formAction === "add"
    ? addPeticion(props)
    : addPeticion(props));
  return res;
});

export const fetchGetPeticionesAsync = createAsyncThunk(
  "Peticiones/fetchGetPeticionesAsync",
  async (forceFetch: boolean | undefined): Promise<IPeticion[]> => {
    const cacheValue = getCacheValue("Peticiones");
    if (cacheValue && !forceFetch) {
      return cacheValue;
    }
    const response = await getPeticionesService();
    setCacheValue("Peticiones", response, 5);
    return response;
  }
);

export const PeticionesSlice = createSlice({
  name: "Peticiones",
  initialState,
  reducers: {
    setFormAction: (
      state,
      action: PayloadAction<PeticioneState["formAction"]>
    ) => {
      state.formAction = action.payload;
    },

    setShowModal: (
      state,
      action: PayloadAction<PeticioneState["showModal"]>
    ) => {
      state.showModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetPeticionesAsync.pending, (state) => {
        state.statusPeticiones = "loading";
      })
      .addCase(
        fetchGetPeticionesAsync.fulfilled,
        (state, action: PayloadAction<IPeticion[]>) => {
          state.peticiones = action.payload;
          state.statusPeticiones = "success";
        }
      )
      .addCase(fetchGetPeticionesAsync.rejected, (state) => {
        state.statusPeticiones = "fail";
      })
      .addCase(fetchAddPeticioneAsync.pending, (state) => {
        state.statusModal = "loading";
      })
      .addCase(fetchAddPeticioneAsync.fulfilled, (state) => {
        state.statusModal = "success";
        state.showModal = false;
      })
      .addCase(fetchAddPeticioneAsync.rejected, (state) => {
        state.statusModal = "fail";
      });
  },
});

// Export actions
export const { setFormAction, setShowModal } = PeticionesSlice.actions;
// Definición de selectores
export const getPeticioneFields = (state: RootState) => {
  return {
    name: state.peticiones.name,
  };
};
export const getPeticiones = (state: RootState) => state.peticiones.peticiones;

export const getShowModal = (state: RootState) => state.peticiones.showModal;
export const getFormAction = (state: RootState) => state.peticiones.formAction;
export const getStatusPeticiones = (state: RootState) =>
  state.peticiones.statusPeticiones;
export const getPeticionesQuery = (state: RootState, query: string) => {
  if (query) {
    return state.peticiones.peticiones.filter(
      (d: any) =>
        d.email.toLowerCase().includes(query) ||
        d.name.toLowerCase().includes(query) ||
        d.cedula.toLowerCase().includes(query)
    );
  } else {
    return state.peticiones.peticiones;
  }
};
export const getStatusModal = (state: RootState) =>
  state.peticiones.statusModal;

export default PeticionesSlice.reducer;
