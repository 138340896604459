import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  fetchGetBannersHomeAsync,
  getBannersProductsQuery,
  setBannerToEdit,
  setShowModal2,
} from "modules/dashboard/redux/bannersSlice";
import { deleteBanner } from "modules/dashboard/services/bannersService";
import React, { useEffect, useRef, useState } from "react";
import Pagination from "share/Pagination";
import SearchField from "share/SearchField";
import Swal from "sweetalert2";

const BannersProductsTable = ({ color }: any) => {
  const dispatch = useAppDispatch();

  const [query, setQuery] = useState("");
  const banners = useAppSelector((state) =>
    getBannersProductsQuery(state, query)
  );

  const activePag = useRef(0);

  const onClickDeleteProduct = (id: string) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "Una vez eliminado, ¡no podrá recuperar el producto!",
      icon: "warning",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then((res) => {
      if (res.isConfirmed) {
        deleteBanner(id).then(() => {
          dispatch(fetchGetBannersHomeAsync(true));
        });
      }
    });
  };
  const onClickUpdateProduct = (product: any) => {
    dispatch(setBannerToEdit(product));
    dispatch(setShowModal2(true));
  };

  return (
    <div
      className={`relative p-2 flex flex-col min-w-0 break-words w-full mb-6 rounded `}
    >
      <div className="rounded-t mb-0 px-4 border-0">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full px-4 max-w-full mb-4 ">
            <div className=" absolute top-0 right-0 flex justify-end mb-2">
              {/* <SearchField value={query} onChange={setQuery} /> */}
            </div>
          </div>
        </div>
      </div>
      <table className="items-center w-full bg-transparent border-collapse ">
        <thead>
          <tr>
            <th
              className={`px-4 text-start align-middle border border-solid py-3 text-sm lg:text-base uppercase border-l-0 border-r-0  font-semibold  ${
                color === "light"
                  ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                  : "bg-red-800 text-red-300 border-red-700"
              }`}
            >
              Sección
            </th>

            <th
              className={`px-4 align-middle border  border-solid py-3 text-sm lg:text-base uppercase border-l-0 border-r-0  font-semibold text-left ${
                color === "light"
                  ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                  : "bg-red-800 text-red-300 border-red-700"
              }`}
            >
              Imagen banner
            </th>

            <th
              className={`px-2 text-center max-w-[150px] align-middle border border-solid py-3 text-sm lg:text-base uppercase border-l-0 border-r-0  font-semibold ${
                color === "light"
                  ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                  : "bg-red-800 text-red-300 border-red-700"
              }`}
            >
              opciones
            </th>
          </tr>
        </thead>
        <tbody>
          {banners.map((p, i) => (
            <tr key={p.id} className="py-2">
              <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-sm lg:text-base  p-2">
                {p.type}
              </td>
              <td className="flex justify-start">
                <img
                  src={p.url}
                  className="h-20  bg-white rounded border"
                  alt="..."
                />
              </td>

              <td className="border-t-0 w-[80px] px-4 align-middle border-l-0 border-r-0 text-sm lg:text-base  p-2">
                <div className="w-full flex justify-center ">
                  <button
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-0.5  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                    onClick={() => onClickUpdateProduct(p)}
                  >
                    <i className="fa fa-pencil" />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BannersProductsTable;
