import React, { useEffect, useState } from "react";
import { HiAdjustments, HiClipboardList, HiUserCircle } from "react-icons/hi";
import { MdDashboard } from "react-icons/md";
import {
  fetchGetCartProductsAsync,
  getStatusCartProducts,
} from "modules/front/redux/cartProductSlice";
import {
  fetchGetSubCategorysForCategoryAsync,
  getStatusSubCategorys,
} from "modules/front/redux/subCategorySlice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  fetchGetProductsForCategoryAsync,
  getStatusProducts,
} from "modules/front/redux/productSlice";
import LoaderBlue from "share/LoaderBlue";
import TableProducts from "modules/front/components/products/TableProducts";
interface Props {
  subcategoria: any;
  activeTab: any;
}
const ReloadProducts: React.FC<Props> = ({ subcategoria, activeTab }) => {
  const dispatch = useAppDispatch();
  const statusProducts = useAppSelector(getStatusProducts);
  const statusCartProducts = useAppSelector(getStatusCartProducts);

  const reiniciarSubcategoria = () => {};
  useEffect(() => {
    if (statusProducts === "idle") {
      dispatch(
        fetchGetProductsForCategoryAsync({
          name: subcategoria,
          forceFetch: false,
        })
      );
    }

    if (statusCartProducts === "idle") {
      dispatch(fetchGetCartProductsAsync());
      dispatch(fetchGetCartProductsAsync());
    }
  }, []);

  useEffect(() => {
    dispatch(
      fetchGetProductsForCategoryAsync({
        name: subcategoria,
        forceFetch: true,
      })
    );

    dispatch(fetchGetCartProductsAsync());
  }, [activeTab, subcategoria]);
  return (
    <div className="flex  justify-center w-full">
      <div className="w-full flex flex-col gap-4 p-4 max-w-[1300px]">
        <div className="flex  justify-end w-full">
          <button
            type="button"
            onClick={() => {
              dispatch(
                fetchGetProductsForCategoryAsync({
                  name: subcategoria,
                  forceFetch: true,
                })
              );
            }}
            className="bg-red-600 mr-6 text-white active:bg-red-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none  ease-linear transition-all duration-150"
          >
            Recargar productos
          </button>
        </div>
        {statusProducts === "success" ? (
          <TableProducts
            reiniciarSubcategoria={reiniciarSubcategoria}
          ></TableProducts>
        ) : (
          <div className="h-full min-h-[500px] w-full flex justify-center items-center">
            <LoaderBlue></LoaderBlue>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReloadProducts;
