import { useAppDispatch, useAppSelector } from "app/hooks";
import TableCarProducts from "modules/front/components/carrito/TableCarProducts";
import {
  fetchGetCartProductsAsync,
  getStatusCartProducts,
} from "modules/front/redux/cartProductSlice";
import React, { useEffect } from "react";
import LoaderBlue from "share/LoaderBlue";

const GenerarPdf = () => {
  const dispatch = useAppDispatch();
  const statusCartProducts = useAppSelector(getStatusCartProducts);
  useEffect(() => {
    const getStatus = async () => {
      if (statusCartProducts === "idle") dispatch(fetchGetCartProductsAsync());
    };

    getStatus();
  }, [dispatch, statusCartProducts]);

  return (
    <div className="flex  justify-center w-full">
      <div className="w-full flex flex-col gap-4 p-4 max-w-[1300px]">
        <div className="flex  justify-end w-full">
          {statusCartProducts === "success" ? (
            <TableCarProducts whatappActive={false}></TableCarProducts>
          ) : (
            <div className="h-full min-h-[500px] w-full flex justify-center items-center">
              <LoaderBlue></LoaderBlue>
            </div>
          )}
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default GenerarPdf;
