import React from "react";
import logo from "assets/productos/img/logo_transparente_blanco.png";
function Footer() {
  return (
    <footer className="full text-white bg-black">
      <div className="mx-auto w-full max-w-screen-xl px-4 py-6 xl:px-10 gap-5 lg:py-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 bg-black">
        <div className="col-span-1 max-w-[250px]">
          <img className="h-10" src={logo} alt="" />
          <p className="mt-6 text-sm">
            Somos una empresa de crédito de libranza con más de 15 años en
            Santander.
          </p>
        </div>
        <div className="col-span-1 lg:flex lg:justify-center">
          <div className="max-w-[250px] w-full">
            <p className="font-semibold text-base">Políticas</p>
            <p className="mt-6 text-sm max-w">
              Datos
              <br />
              Cookies
            </p>
          </div>
        </div>
        <div className="col-span-1 lg:flex lg:justify-center">
          <div>
            <p className="font-semibold">Contacto</p>
            <p className="mt-6 text-sm">
              Calle 34 # 10-65
              <br />
              Bucaramanga
              <br />
            </p>
            <a href="mailto:credito@creditopopularbga.com">
              credito&#64;creditopopularbga.com
            </a>
            <br />
            <a href="tel:+573152489638">+57 315 2489638</a>
          </div>
        </div>
      </div>

      <div className="mx-auto w-full max-w-screen-xl p-2 px-4 sm:p-4 py-6 lg:py-8 xl:px-10">
        <div className="flex items-center justify-between">
          <span className="text-sm sm:text-center">
            © 2024 Desarollado por
            <a href="https://vozavoz.com.co/" className="hover:underline">
              &nbsp;Vozavoz
            </a>
            .
          </span>
          <div className="flex mt-0 justify-end items-center">
            <p></p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
