import { useAppSelector } from "app/hooks";
import { getProductsQuery } from "modules/front/redux/productSlice";
// import {
//   deleteProduct,
//   getProducts,
// } from "modules/front/services/productService";
import React, { useEffect, useRef, useState } from "react";
import Pagination from "share/Pagination";
import SearchField from "share/SearchField";
// import Swal from "sweetalert2";
// import { IProduct } from "utils/models/IProducts";
import CardProducto from "./CardProducto";
interface Props {
  subcategoria?: any;
  reiniciarSubcategoria: () => void;
}
const TableProducts: React.FC<Props> = ({
  subcategoria,
  reiniciarSubcategoria,
}) => {
  // const dispatch = useAppDispatch();

  const [query, setQuery] = useState(subcategoria);
  const products = useAppSelector((state) => getProductsQuery(state, query));
  const sort = 9;
  const paggination = Array(Math.ceil(products.length / sort))
    .fill(0)
    .map((_, i) => i + 1);

  const activePag = useRef(0);
  const [data, setData] = useState(
    products.slice(activePag.current * sort, (activePag.current + 1) * sort)
  );

  const onChangePagination = (i: number) => {
    activePag.current = i;
    setData(
      products.slice(activePag.current * sort, (activePag.current + 1) * sort)
    );
  };
  // const onClickDeleteProduct = (id: string) => {
  //   Swal.fire({
  //     title: "¿Estas seguro?",
  //     text: "Una vez eliminado, ¡no podrá recuperar el producto!",
  //     icon: "warning",
  //     showCloseButton: true,
  //     showCancelButton: true,
  //     confirmButtonColor: "#dc3545",
  //     confirmButtonText: "Eliminar",
  //     cancelButtonText: "Cancelar",
  //   }).then((res) => {
  //     if (res.isConfirmed) {
  //       deleteProduct(id).then(() => {
  //         dispatch(fetchGetProductsAsync(true));
  //       });
  //     }
  //   });
  // };
  // const onClickUpdateProduct = (product: IProduct) => {
  //   dispatch(setProductToEdit(product));
  //   dispatch(setShowModal(true));
  // };
  useEffect(() => {
    activePag.current = 0;
    setData(products.slice(0 * sort, (0 + 1) * sort));
  }, [query]);

  useEffect(() => {
    setQuery(subcategoria);
  }, [subcategoria]);
  return (
    <div
      className={`relative p-2 flex flex-col min-w-0 break-words w-full mb-6 rounded `}
    >
      <div className="rounded-t mb-0 px-4 border-0">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full px-4 max-w-full mb-10 ">
            <div
              onClick={() => reiniciarSubcategoria()}
              className=" absolute top-0 right-0 flex justify-end mb-2"
            >
              <SearchField value={query} onChange={setQuery} />
            </div>
          </div>
        </div>
      </div>
      <div className="block w-full overflow-x-auto">
        <div className="grid  grid-cols-1 sm:grid-cols-2 lg:grid-cols-3   gap-3  min-h-[700px]">
          {data.map((p) => (
            <div className="col-span-1  pt-2">
              {" "}
              <CardProducto key={p.id} product={p}></CardProducto>
            </div>
          ))}
        </div>
        <div className="flex text-center justify-between items-center mt-3">
          <div className="dataTables_info text-sm">
            Mostrando del {activePag.current * sort + 1} al{" "}
            {products.length > (activePag.current + 1) * sort
              ? (activePag.current + 1) * sort
              : products.length}{" "}
            de {products.length} Productos
          </div>
          <div className="dataTables_paginate paging_simple_numbers">
            <Pagination
              size="sm"
              gutter
              variant="primary"
              activePag={activePag}
              onChangePagination={onChangePagination}
              limit={paggination.length}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableProducts;
