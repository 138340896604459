import React from "react";

const Header = () => {
  return (
    <div className="relative bg-azul-principal md:pt-32 pb-32 pt-12">
      <div className="px-4 md:px-10 mx-auto w-full">
        <div>
          <div className="flex flex-wrap"></div>
        </div>
      </div>
    </div>
  );
};

export default Header;
