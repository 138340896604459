import React, { useEffect } from "react";
import ProductModal from "../components/products/productsModal";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  fetchGetProductsAsync,
  getStatusProducts,
} from "../redux/productSlice";
import ProductTable from "../components/products/ProductTable";
import Loader from "share/Loader";
import SubCategorysTable from "../components/products/SubCategorysTable";
import SubCategoryModal from "../components/products/SubCategoryModal";
import {
  fetchGetSubCategorysAsync,
  getStatusSubCategorys,
} from "../redux/subCategorySlice";
import PeticionesPrestamosTable from "../components/products/PeticionesPrestamosTable copy";
import {
  fetchGetPeticionesAsync,
  getStatusPeticiones,
} from "../redux/peticionesSlice";
import PeticionesModal from "../components/products/PeticionesModal";
import BannersTable from "../components/banners/BannersTable";
import BannerModal from "../components/banners/BannerModal";
import {
  fetchGetBannersHomeAsync,
  fetchGetBannersProductsAsync,
  getStatusBannersHome,
  getStatusBannersProducts,
} from "../redux/bannersSlice";
import BannersProductsTable from "../components/banners/BannersProductsTable";
import BannerProductsModal from "../components/banners/BannerProductsModal";

const Banners: React.FC = () => {
  const dispatch = useAppDispatch();
  const statusBannerHome = useAppSelector(getStatusBannersHome);
  const statusBannerProducts = useAppSelector(getStatusBannersProducts);
  useEffect(() => {
    const getStatus = async () => {
      console.log(statusBannerProducts);
      if (statusBannerHome === "idle")
        await dispatch(fetchGetBannersHomeAsync());

      if (statusBannerProducts === "idle")
        await dispatch(fetchGetBannersProductsAsync());
    };
    getStatus();
  }, []);

  return (
    <div className="flex flex-wrap h-full min-h[80vh]">
      <div className="w-full lg:w-full px-4 grid grid-cols-12 gap-4">
        <div className="col-span-12 lg:col-span-6 relative card bg-white flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-black text-base lg:text-2xl  font-bold">
                Crear y editar Banners Home
              </h6>
              <div className="">
                {/* <i className="fa-solid fa-ellipsis-vertical"></i> */}

                {statusBannerHome === "success" ? (
                  <BannerModal></BannerModal>
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          </div>
          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            {/* <app-table-product [color]="'light'"></app-table-product> */}
            {statusBannerHome === "success" ? (
              <BannersTable color={"light"}></BannersTable>
            ) : (
              <Loader />
            )}
          </div>
        </div>

        <div className="col-span-12 lg:col-span-6 relative bg-white flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex  justify-between">
              <h6 className="text-black text-base lg:text-2xl font-bold">
                Editar banners de productos
              </h6>
              <div className="">
                {statusBannerProducts === "success" ? (
                  <BannerProductsModal></BannerProductsModal>
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          </div>
          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            {/* <app-table-product [color]="'light'"></app-table-product> */}
            {statusBannerProducts === "success" ? (
              <BannersProductsTable color={"light"}></BannersProductsTable>
            ) : (
              <Loader />
            )}
          </div>
        </div>
        <div className="col-span-12"></div>
      </div>
    </div>
  );
};

export default Banners;
