import { useAppDispatch, useAppSelector } from "app/hooks";

// import ProductCard from "components/ProductCard";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Modal, Dropdown } from "flowbite-react";
import {
  fetchAddSubCategoryAsync,
  fetchGetSubCategorysAsync,
  getFormAction,
  getShowModal,
  setFormAction,
  setShowModal,
  getStatusModal,
  getSubCategoryFields,
} from "../../redux/subCategorySlice";
import * as Yup from "yup";
import { fetchGetProductsAsync } from "modules/dashboard/redux/productSlice";

const ProductSchema = Yup.object().shape({
  name: Yup.string().required("Nombre requerido"),
  category: Yup.string().required("category requerida"),
});

const SubCategoryModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const show = useAppSelector(getShowModal);
  const formAction = useAppSelector(getFormAction);
  const statusModal = useAppSelector(getStatusModal);
  const SubCategoryFields = useAppSelector(getSubCategoryFields);

  const initialValue =
    formAction === "update"
      ? SubCategoryFields
      : {
          name: "",
          category: "",
        };

  useEffect(() => {}, [show]);

  return (
    <>
      <Dropdown
        label={
          <div className="bg-red-600 text-white active:bg-red-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">
            <i className="fa-solid fa-ellipsis-vertical"></i>
          </div>
        }
        arrowIcon={false}
        inline
      >
        <Dropdown.Item
          onClick={() => {
            dispatch(setShowModal(true));
            dispatch(setFormAction("add"));
          }}
        >
          Crear subcategoría
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            dispatch(fetchGetSubCategorysAsync(true));
          }}
        >
          Actualizar
        </Dropdown.Item>
      </Dropdown>

      <Modal
        className="fade"
        dismissible
        show={show}
        onClose={() => {}}
      >
        <Modal.Header>
          <div>
            {formAction === "update" ? "Actualizar" : "Crear nuevo"} producto
          </div>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValue}
            validationSchema={ProductSchema}
            onSubmit={async (values) => {
              await dispatch(fetchAddSubCategoryAsync({ ...values }));
              await dispatch(fetchGetSubCategorysAsync(true));
              formAction === "update"
                ? await dispatch(fetchGetProductsAsync(true))
                : await dispatch(fetchGetProductsAsync());
            }}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form className="grid grid-cols-2" onSubmit={handleSubmit}>
                <div className=" col-span-2 mb-4">
                  <label htmlFor="val-name" className="text-label">
                    Nombre <span className="text-red-500">*</span>
                  </label>
                  <div className="input-group transparent-append mb-2">
                    {/* <span className="input-group-text">
                      <i className="fa fa-user"></i>
                    </span> */}
                    <input
                      type="text"
                      className={`form-control w-full ${
                        values.name && errors.name ? "is-invalid" : "is-valid"
                      }`}
                      id="val-name"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      autoComplete="off"
                    />
                    <div
                      className="invalid-feedback animated fadeInUp text-red-500"
                      style={{ display: "block" }}
                    >
                      {errors.name && errors.name}
                    </div>
                  </div>
                </div>
                <div className=" col-span-1 mb-4 pr-1">
                  <label htmlFor="val-name" className="text-label">
                    Categoría <span className="text-red-500">*</span>
                  </label>
                  <div className=" input-group transparent-append mb-2">
                    {/* <span className="input-group-text">
                      <i className="fa fa-user"></i>
                    </span> */}
                    <select
                      className={`form-control w-full ${
                        values.category && errors.category
                          ? "is-invalid"
                          : "is-valid"
                      }`}
                      id="val-names"
                      name="category"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.category}
                      autoComplete="off"
                      style={{ display: "block" }}
                    >
                      <option value="" label="Selecciona una categoría">
                        Selecciona una category
                      </option>
                      <option value="muebles" label="Muebles">
                        {" "}
                        muebles
                      </option>
                      <option value="tecnologia" label="Tecnología">
                        Tecnología
                      </option>

                      <option
                        value="electrodomesticos"
                        label="Electrodomésticos"
                      >
                        Electrodomésticos
                      </option>
                      <option value="deportes" label="Deportes">
                        Deportes
                      </option>
                    </select>
                    <div
                      className="invalid-feedback text-red-500 animated fadeInUp"
                      style={{ display: "block" }}
                    >
                      {errors.category && errors.category}
                    </div>
                  </div>
                </div>

                {/* Otros campos similares */}

                <div className="col-span-2">
                  <button
                    type="submit"
                    className="lg:text-base bg-vinotinto hover:bg-red-800 text-white  py-1 px-6 mx-3 rounded-full"
                    disabled={isSubmitting}
                  >
                    {formAction === "update" ? "Actualizar" : "Guardar"}
                    {statusModal === "loading" && (
                      <>
                        <i className="fa fa-cog fa-spin fa-fw" />
                        <span className="sr-only">Loading...</span>
                      </>
                    )}
                  </button>

                  <button
                    className=" hover:bg-vinotinto hover:text-white px-6 py-1 hover:rounded-full mx-3"
                    type="button"
                    onClick={() => dispatch(setShowModal(false))}
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default SubCategoryModal;
