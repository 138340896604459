import React from "react";
import logo from "assets/productos/img/logo_transparente_azul_sintextos.png";
const PdfHeader = ({ user }: any) => {
  return (
    <div className="w-full flex justify-between px-4 py-2">
      <div className="flex items-center">
        <img className=" h-12 mt-2" src={logo} alt="" />
      </div>
      <div className="  gap-2 border-[1px] p-2 border-black rounded-sm">
        <p className=" text-sm">{user.name}</p>
        <p className=" text-sm">{user.email}</p>
        <p className=" text-sm">+57 {user.celular}</p>
      </div>
    </div>
  );
};

export default PdfHeader;
