import { Dropdown } from "flowbite-react";
import React, { useEffect } from "react";
import UsersTable from "../components/users/UsersTable";
import UserCreate from "../components/users/UserCreate";
import { fetchusersAsync, getStatususers } from "../redux/userSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import Loader from "share/Loader";

const Users = () => {
  const dispatch = useAppDispatch();

  const statusUsers = useAppSelector(getStatususers);

  useEffect(() => {
    if (statusUsers === "idle") dispatch(fetchusersAsync());
  }, []);

  return (
    <div className="flex flex-wrap h-full min-h[80vh]">
      <div className="w-full lg:w-6/12 px-4">
        <div className="relative bg-white flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-black text-xl font-bold">Usuarios</h6>
              <div className="bg-red-600 text-white active:bg-red-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">
                {statusUsers === "success" ? (
                  <UserCreate></UserCreate>
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          </div>
          <div>
            {statusUsers === "success" ? <UsersTable></UsersTable> : <Loader />}
          </div>
        </div>
      </div>
      {/* <div className="w-full lg:w-4/12 px-4">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg">
          <div className="px-6 pb-6"></div>
        </div>
      </div> */}
    </div>
  );
};

export default Users;
