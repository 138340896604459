import { useAppSelector } from "app/hooks";
import React, { useEffect, useRef, useState } from "react";
import Pagination from "share/Pagination";
import SearchField from "share/SearchField";
import CardProducto from "../products/CardProducto";
import { getCartProducts } from "modules/front/redux/cartProductSlice";
import Pdfgenerate from "modules/front/pdf/Pdfgenerate";
interface Props {
  whatappActive: boolean;
}
const TableCarProducts: React.FC<Props> = ({ whatappActive }) => {
  const userData = {
    name: "sad",
    email: "asdasd",
    celular: "asadasd",
  };

  const [query, setQuery] = useState("");
  const products = useAppSelector((state) => getCartProducts(state, query));
  const sort = 9;
  const paggination = Array(Math.ceil(products.length / sort))
    .fill(0)
    .map((_, i) => i + 1);

  const activePag = useRef(0);
  const [data, setData] = useState(
    products.slice(activePag.current * sort, (activePag.current + 1) * sort)
  );

  const onChangePagination = (i: number) => {
    activePag.current = i;
    setData(
      products.slice(activePag.current * sort, (activePag.current + 1) * sort)
    );
  };
  useEffect(() => {
    activePag.current = 0;
    setData(products.slice(0 * sort, (0 + 1) * sort));
  }, [query]);
  useEffect(() => {}, []);

  async function sendProductsViaWhatsApp() {
    const phoneNumber = "+573152489638"; // Reemplaza esto con el número de teléfono al que deseas enviar el mensaje
    const message = formatMessageWithImages(); // Función para formatear el mensaje con imágenes
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappUrl, "_blank");
  }

  function formatMessageWithImages() {
    let message = "Hola, estoy interesado en los siguientes productos: \n"; // Inicializa el mensaje
    products.forEach((product) => {
      // Por cada producto en el array, agrega su información al mensaje
      message += `imagen ${product.imagen}\n ${product.nombreDescriptivo}\n Código: ${product.codigo}\nCategoría: ${product.categoria}\n------------------------------\n \n`;
    });
    return message;
  }
  return (
    <div
      className={`relative p-2 flex flex-col min-w-0 break-words w-full mb-6 rounded `}
    >
      <div className="rounded-t mb-0 px-4 border-0">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full px-4 max-w-full mb-10 ">
            <div className=" absolute top-0 right-0 flex justify-end mb-2">
              <SearchField value={query} onChange={setQuery} />
            </div>
          </div>
        </div>
      </div>
      <div className="block w-full overflow-x-auto">
        <div className="grid gap-3 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3  min-h-[500px]">
          {data.map((p, index) => (
            <div key={p.id} className="col-span-1">
              {" "}
              <CardProducto product={p}></CardProducto>
            </div>
          ))}
        </div>
        <div className="flex text-center justify-between items-center mt-3">
          <div className="dataTables_info text-sm lg:text-base">
            Mostrando del {activePag.current * sort + 1} al{" "}
            {products.length > (activePag.current + 1) * sort
              ? (activePag.current + 1) * sort
              : products.length}{" "}
            de {products.length} Productos
          </div>
          <div className="dataTables_paginate paging_simple_numbers">
            <Pagination
              size="sm"
              gutter
              variant="primary"
              activePag={activePag}
              onChangePagination={onChangePagination}
              limit={paggination.length}
            />
          </div>
        </div>
      </div>
      <div>
        <div className="flex flex-col mt-4 items-end">
          {whatappActive ? (
            <button
              className=" mb-5 lg:text-lg xl:text-xl bg-green-500 hover:bg-green-800 text-white font-bold py-1 px-6 rounded-full"
              type="button"
              onClick={sendProductsViaWhatsApp}
            >
              Enviar por whatsapp los productos que deseas cotizar
            </button>
          ) : (
            <></>
          )}
          <Pdfgenerate products={products} user={userData} />
        </div>
      </div>
    </div>
  );
};

export default TableCarProducts;
