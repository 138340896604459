import { useAppDispatch, useAppSelector } from "app/hooks";

// import ProductCard from "components/ProductCard";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import ProductCard from "../products/ProductCard";
import { Button, Modal, Dropdown } from "flowbite-react";
import {
  fetchAddBannerHomeAsync,
  fetchGetBannersHomeAsync,
  getBannersFields,
  getFormAction,
  getShowModal2,
  setFormAction,
  setShowModal2,
  getStatusModal,
  getBannerImage,
  fetchUpdateBannerProductAsync,
  fetchGetBannersProductsAsync,
} from "../../redux/bannersSlice";
import * as Yup from "yup";

import { FileInput, Label } from "flowbite-react";
import { IAddProduct } from "utils/models/IProducts";
import { getSubCategorysQuery } from "modules/dashboard/redux/subCategorySlice";

const ProductSchema = Yup.object().shape({
  url: Yup.string().required("Imagen requerida").nullable(),
});

const BannerProductsModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const show = useAppSelector(getShowModal2);
  const formAction = useAppSelector(getFormAction);
  const statusModal = useAppSelector(getStatusModal);
  const BannerFields = useAppSelector(getBannersFields);
  const image = useAppSelector(getBannerImage);

  const [imgData2, setImgData2] = useState("");
  const [imgData, setImgData] = useState("");

  const initialValue =
    formAction === "update"
      ? BannerFields
      : {
          url: "",
        };

  // const onChangePicture = (e: any) => {
  //   if (e.target.files[0]) {
  //     const reader = new FileReader();
  //     reader.addEventListener("load", () => {
  //       setImgData(reader.result as any);
  //     });
  //     reader.readAsDataURL(e.target.files[0]);
  //   }
  // };

  const onChangePicture = (e: any) => {
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result as any);
        setImgData2(e.target.files[0] as any);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  useEffect(() => {
    if (formAction === "update") {
      setImgData(image);
    } else {
      setImgData("");
    }
  }, [show]);
  const closed = () => {
    dispatch(setShowModal2(true));
  };

  return (
    <>
      <Dropdown
        label={
          <div className="bg-red-600 text-white active:bg-red-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">
            <i className="fa-solid fa-ellipsis-vertical"></i>
          </div>
        }
        arrowIcon={false}
        inline
      >
        {/* <Dropdown.Item
          onClick={() => {
            dispatch(setShowModal2(true));
            dispatch(setFormAction("add"));
          }}
        >
          Crear banner
        </Dropdown.Item> */}
        <Dropdown.Item
          onClick={() => {
            dispatch(fetchGetBannersProductsAsync(true));
          }}
        >
          Actualizar
        </Dropdown.Item>
      </Dropdown>

      <Modal
        className="fade"
        dismissible
        show={show}
        onClose={() => dispatch(setShowModal2(false))}
      >
        <Modal.Header>
          <div>
            {formAction === "update" ? "Actualizar" : "Crear nuevo"} banner
          </div>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValue}
            validationSchema={ProductSchema}
            onSubmit={async (values) => {
              await dispatch(
                fetchUpdateBannerProductAsync({
                  url: imgData,
                })
              );
              await dispatch(fetchGetBannersProductsAsync(true));
            }}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form className="grid grid-cols-2" onSubmit={handleSubmit}>
                {/* Otros campos similares */}
                <div className=" col-span-2 mb-4">
                  <label htmlFor="val-image" className="text-label">
                    Subir imagen
                  </label>
                  <br />

                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="fa fa-upload"></i>
                    </span>
                    <div className="form-file">
                      <input type="hidden" value={values.url} />
                      <input
                        type="file"
                        id="val-url"
                        name="url"
                        accept="image/*"
                        onChange={(e) => {
                          if (e.target.value) {
                            handleChange(e);
                            onChangePicture(e);
                          }
                        }}
                        onBlur={handleBlur}
                      />
                    </div>
                    <div
                      className="invalid-feedback animated fadeInUp text-red-500"
                      style={{ display: "block" }}
                    >
                      {errors.url && errors.url}
                    </div>
                    <span className="text-sm ">
                      {" "}
                      Por favor tamaño 1390 x 450 pixeles
                    </span>
                    <ProductCard image={imgData} />
                  </div>
                </div>

                <div className="col-span-2">
                  <button
                    type="submit"
                    className="lg:text-base bg-vinotinto hover:bg-red-800 text-white  py-1 px-6 mx-3 rounded-full"
                    disabled={isSubmitting}
                  >
                    {formAction === "update" ? "Actualizar" : "Guardar"}
                    {statusModal === "loading" && (
                      <>
                        <i className="fa fa-cog fa-spin fa-fw" />
                        <span className="sr-only">Loading...</span>
                      </>
                    )}
                  </button>

                  <button
                    className=" hover:bg-vinotinto hover:text-white px-6 py-1 hover:rounded-full mx-3"
                    type="button"
                    onClick={() => dispatch(setShowModal2(false))}
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default BannerProductsModal;
