import { PayloadAction, createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { addUser, getUsers, updateUser } from "../services/usersService";
import { IAddUser, IUser } from "utils/models/IUser";

type usersState = {
  id: string;
  email: string;
  password: string;
  rol: string;
  name: string;
  lastName: string;
  showModal: boolean;
  celular: string;

  users: IUser[];

  formAction: "update" | "add";
  statusUsers: "idle" | "loading" | "success" | "fail";
  statusModal: "idle" | "loading" | "success" | "fail";
  statusMoneyRecharges: "idle" | "loading" | "success" | "fail";
};

const initialState: usersState = {
  id: "",
  email: "",
  password: "",
  rol: "",
  name: "",
  lastName: "",
  celular: "",
  showModal: false,

  users: [],

  formAction: "add",
  statusUsers: "idle",
  statusModal: "idle",
  statusMoneyRecharges: "idle",
};

export const fetchAddUserAsync = createAsyncThunk<
  Promise<any>,
  IAddUser,
  { state: RootState }
>("auth/fetchAddUserAsync", async (payload, { getState }) => {
  const {
    users: { formAction, id },
  } = getState();
  const res = await (formAction === "add"
    ? addUser(payload)
    : updateUser({ ...payload, id }));
  return res;
});

export const fetchusersAsync = createAsyncThunk(
  "auth/fetchusersAsync",
  async (): Promise<IUser[]> => {
    const res = await getUsers();

    return res;
  }
);

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setFormAction: (state, action: PayloadAction<usersState["formAction"]>) => {
      state.formAction = action.payload;
    },
    setuserToEdit: (state, action: PayloadAction<IUser>) => {
      state.id = action.payload.id;
      state.email = action.payload.email;
      state.name = action.payload.name;
      state.celular = action.payload.celular;
      state.lastName = action.payload.lastName;
      state.formAction = "update";
    },
    setShowModal: (state, action: PayloadAction<usersState["showModal"]>) => {
      state.showModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchusersAsync.pending, (state) => {
        state.statusUsers = "loading";
      })
      .addCase(
        fetchusersAsync.fulfilled,
        (state, action: PayloadAction<IUser[]>) => {
          state.statusUsers = "success";
          state.users = action.payload;
        }
      )
      .addCase(fetchusersAsync.rejected, (state) => {
        state.statusUsers = "fail";
      })
      .addCase(fetchAddUserAsync.pending, (state) => {
        state.statusModal = "loading";
      })
      .addCase(fetchAddUserAsync.fulfilled, (state) => {
        state.statusModal = "success";
        state.showModal = false;
      })
      .addCase(fetchAddUserAsync.rejected, (state) => {
        state.statusModal = "fail";
      });
  },
});

// Export actions
export const { setFormAction, setuserToEdit, setShowModal } = userSlice.actions;

// Definición de selectores
export const getStatususers = (state: RootState) => state.users.statusUsers;
export const getStatusModal = (state: RootState) => state.users.statusModal;
export const getUsersSelector = (state: RootState) => state.users.users;
export const getUsersQuery = (state: RootState, query: string) => {
  if (query) {
    return state.users.users.filter(
      (d) =>
        d.name.toLowerCase().includes(query) ||
        d.lastName.toLowerCase().includes(query) ||
        d.email.toLowerCase().includes(query)
    );
  } else {
    return state.users.users;
  }
};
export const getFormAction = (state: RootState) => state.users.formAction;
export const getuserFields = (state: RootState) => {
  return {
    name: state.users.name,
    email: state.users.email,
    password: "",
    lastName: state.users.lastName,
    celular: state.users.celular,
  };
};
export const getShowModal = (state: RootState) => state.users.showModal;

export default userSlice.reducer;
