import React, { useEffect, useState } from "react";
// import logo from "../../../assets/productos/img/logo_transparente_azul_sintextos.png";
import logo from "assets/productos/img/logo_transparente_azul_sintextos.png";
import logoBlanco from "assets/productos/img/logo_transparente_blanco.png";
import { Link } from "react-router-dom";
import { rutas } from "utils/routes";
import { fetchGetSubCategorysForCategoryAsync } from "../redux/subCategorySlice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  fetchGetProductsAsync,
  fetchGetProductsForCategoryAsync,
} from "../redux/productSlice";
import {
  fetchGetCartProductsAsync,
  getCartProducts,
} from "../redux/cartProductSlice";
const Header: React.FC = ({}) => {
  const [collapseShow, setCollapseShow] = useState(false);

  const carProducts = useAppSelector((state) => getCartProducts(state, ""));
  const rutaCompleta = window.location.href;
  // const path = rutaCompleta.split("/")[3]; // Cambia el índice según la posición del valor deseado

  const [path, setPath] = useState(rutaCompleta.split("/")[3]);
  const [path2, setPath2] = useState(false);

  const dispatch = useAppDispatch();
  const toggleCollapseShow = () => {
    setCollapseShow(!collapseShow);
  };
  const change = async () => {
    setPath2(!path2);
  };
  useEffect(() => {
    return () => {
      const ruta = window.location.pathname;
      setPath(ruta);
      setPath(ruta.split("/")[1]);
      const valorDespuesDeBarra = ruta.split("/")[1];
      dispatch(
        fetchGetSubCategorysForCategoryAsync({
          name: valorDespuesDeBarra,
          forceFetch: true,
        })
      );
      if (valorDespuesDeBarra === "home") {
        dispatch(fetchGetProductsAsync());
      } else {
        dispatch(
          fetchGetProductsForCategoryAsync({
            name: valorDespuesDeBarra,
            forceFetch: true,
          })
        );
      }
      dispatch(fetchGetCartProductsAsync());

      // alert(valorDespuesDeBarra);
    };
  }, [dispatch, path2]);
  return (
    <div className="flex justify-center w-full bg-white border-b-[3px] border-red-700">
      <div className="h-min-[60px] p-2 w-full justify-between lg:px-10 items-center flex">
        <figure className="h-[40px] md:h-[50px] mb-[-8px]">
          <Link to="/">
            <img className="h-[40px] md:h-[50px]" src={logo} alt="" />
          </Link>
        </figure>

        {/* nav movile */}
        <div
          onClick={() => setCollapseShow(false)}
          className={`${
            collapseShow
              ? "fixed md:hidden w-screen h-screen flex flex-col gap-2 py-5 pl-5 text-base left-0 top-0  bg-black opacity-30 z-40"
              : "hidden"
          }`}
        ></div>
        <nav>
          <ul
            className={`${
              collapseShow
                ? "fixed md:hidden flex flex-col gap-2 py-5 pl-5 text-base left-0 top-0 h-screen w-full bg-azul-principal z-50 max-w-[250px]"
                : "hidden"
            }`}
          >
            <li
              className={`${
                !collapseShow
                  ? "hidden"
                  : "h-[40px] md:h-[50px] w-full flex justify-center mb-3"
              }`}
            >
              <Link to="/">
                <img className="h-[40px] md:h-[50px]" src={logoBlanco} alt="" />
              </Link>
            </li>
            {rutas.front.children.map((ruta: any, index: any) => (
              <li key={index} className="text-">
                <Link
                  to={ruta.url}
                  onClick={() => {
                    toggleCollapseShow();
                    change();
                  }}
                  className={`text-xs md:text-xs lg:text-base uppercase py-3 font-bold block ${
                    path === ruta.url
                      ? "text-black bg-white rounded-l-lg pl-4 "
                      : "text-white hover:text-azul-principal  hover:bg-white hover:rounded-l-lg hover:pl-4"
                  }}`}
                >
                  {ruta.title}
                </Link>
              </li>
            ))}
            <li className="text-">
              <Link
                to="/carrito"
                onClick={() => {
                  toggleCollapseShow();
                  change();
                }}
                className={`text-xs md:text-sm lg:text-base uppercase py-3 font-bold block ${
                  path === "carrito"
                    ? "text-black bg-white rounded-l-lg pl-4 "
                    : "text-white hover:text-azul-principal hover:bg-white hover:rounded-l-lg hover:pl-4"
                }}`}
              >
                Carrito2
              </Link>
            </li>
          </ul>

          <ul className="hidden md:flex gap-3 lg:gap-4 xl:gap-6">
            {rutas.front.children.map((ruta: any, index: any) => (
              <li key={index} className="">
                <Link
                  to={ruta.url}
                  onClick={() => {
                    change();
                  }}
                  className={`text-xs md:text-xs lg:text-base  py-3 font-bold block ${
                    path === ruta.url
                      ? "text-azul-principal hover:text-vinotinto"
                      : "text-cafe hover:text-azul-principal"
                  }`}
                >
                  {ruta.title}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
        <Link
          to="/carrito"
          className={`text-xs hidden md:flex flex-col justify-end md:text-xs lg:text-base uppercase pb-3 pt-2 font-bold  ${
            path === "carrito"
              ? "text-azul-principal hover:text-vinotinto"
              : "text-cafe hover:text-azul-principal"
          }`}
          onClick={() => {
            change();
          }}
        >
          <span className="flex justify-end  items-start h-[15px] m-0 lg:mb-1 pb-[2px] text-[8px] md:text-xs lg:text-sm font-semibold">
            {carProducts.length}
          </span>
          <i className="fa-solid fa-cart-shopping"></i>
        </Link>
        <button onClick={() => toggleCollapseShow()} className=" md:hidden">
          <i className="fa-solid fa-bars"></i>
        </button>
      </div>
    </div>
  );
};

export default Header;
