import { combineReducers, configureStore } from "@reduxjs/toolkit";
import AuthReducer from "modules/dashboard/redux/authSlice";
import productReducer from "modules/dashboard/redux/productSlice";
import productFrontReducer from "modules/front/redux/productSlice";
import subCategoryReducer from "modules/dashboard/redux/subCategorySlice";
import subCategoryFrontReducer from "modules/front/redux/subCategorySlice";
import userReducer from "modules/dashboard/redux/userSlice";
import cartProductsReducer from "modules/front/redux/cartProductSlice";
import peticionesReducer from "modules/dashboard/redux/peticionesSlice";
import bannersReducer from "modules/dashboard/redux/bannersSlice";
const appReducer = combineReducers({
  auth: AuthReducer,
  products: productReducer,
  productsFront: productFrontReducer,
  subCategorys: subCategoryReducer,
  subCategorysFront: subCategoryFrontReducer,
  users: userReducer,
  cartProducts: cartProductsReducer,
  peticiones: peticionesReducer,
  banners: bannersReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "auth/logout") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
