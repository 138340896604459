import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../../firebase";
// import { getDownloadURL, ref, uploadString } from "firebase/storage";
// import { createAccountOrder } from "services/accountOrderService";
// import { addPlanProps, IPlan } from "utils/models/IPlan";
// import { IPlatform } from "utils/models/IPlatform";
import {
  showErrorForGetData,
  showErrorMessage,
  showSuccessMessage,
} from "share/serviceMessages";
// import { IAddProduct, IProduct } from "utils/models/IProducts";
import dayjs from "dayjs";
const obj = "Peticiones";

// const uploadImage = async (img: string, name: string, category?: string) => {
//   const storageRef = ref(
//     storage,
//     `products/${category}/${dayjs().unix()}-${name}.jpeg`
//   );
//   await uploadString(storageRef, img, "data_url");
//   return getDownloadURL(storageRef);
// };

export const addPeticion = async (payload: any) => {
  try {
    const creationDate = serverTimestamp();
    // const creationDate = serverTimestamp();

    // const imgUrl = await uploadImage(
    //   payload.imagen,
    //   payload.codigo,
    //   payload.categoria
    // );
    const docRef = await addDoc(collection(db, "Peticiones"), {
      ...payload,
      fechaCreacion: creationDate,
    });

    // await createAccountOrder(docRef.id);

    showSuccessMessage({ obj, action: "add" });
    return docRef.id;
  } catch (error) {
    showErrorMessage({ obj, error, action: "add" });
    return Promise.reject();
  }
};

// export const updateProduct = async (payload: IProduct) => {
//   try {
//     const productRef = doc(db, "Products", payload.id);
//     if (payload.imagen.startsWith("http")) {
//       await updateDoc(productRef, {
//         codigo: payload.codigo,
//         categoria: payload.categoria,
//         subCategoria: payload.subCategoria,
//         uidSubCategory: payload.uidSubCategory,

//         nombreDescriptivo: payload.nombreDescriptivo,
//         precio: payload.precio,
//         descripcion: payload.descripcion,
//       });
//     } else {
//       const imgUrl = await uploadImage(payload.imagen, payload.codigo);
//       await updateDoc(productRef, {
//         imagen: imgUrl,
//         codigo: payload.codigo,
//         categoria: payload.categoria,
//         subCategoria: payload.subCategoria,
//         nombreDescriptivo: payload.nombreDescriptivo,
//         precio: payload.precio,
//         descripcion: payload.descripcion,
//       });
//     }
//     showSuccessMessage({ obj, action: "update" });
//   } catch (error) {
//     showErrorMessage({ obj, error, action: "update" });
//     return Promise.reject();
//   }
// };

export const deleteProduct = async (uid: string) => {
  try {
    await deleteDoc(doc(db, "Peticiones", uid));
    showSuccessMessage({ obj, action: "delete" });
  } catch (error) {
    showErrorMessage({ obj, error, action: "delete" });
    return Promise.reject();
  }
};

export const getPeticiones = async () => {
  try {
    // const querySnapshot = await getDocs(collection(db, "Peticiones"));
    const querySnapshot = await getDocs(
      query(collection(db, "Peticiones"), orderBy("fechaCreacion", "desc"))
    );
    let data: any[] = [];
    querySnapshot.forEach((doc) => {
      const product = doc.data();
      const fechaCreacion = dayjs(product.fechaCreacion.toDate()).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      data.push({
        id: doc.id,

        ...product,
        fechaCreacion,
      } as any);
    });
    return data;
  } catch (error) {
    showErrorForGetData({ obj: "Peticiones", error });
    return Promise.reject();
  }
};
