import Swal from "sweetalert2";
// import { logout } from 'redux/authSlice';
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import {
  getFirestore,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { NavigateFunction } from "react-router-dom";
import { AppDispatch } from "app/store";
import { IAddUser, IUser } from "utils/models/IUser";
import { initializeApp } from "firebase/app";
import { setCacheValue } from "utils/cache";
import firebaseApp, { db, firebaseConfig } from "../../../firebase";
import { logout } from "../redux/authSlice";
import { showErrorMessage, showSuccessMessage } from "share/serviceMessages";

const obj = "Usuario";
type loginProps = {
  email: string;
  password: string;
  navigate: NavigateFunction;
};

export function formatError(errorMessage: string) {
  switch (errorMessage) {
    case "auth/user-not-found":
      // return 'Email already exists';

      Swal.fire("Oops", "Usuario no encontrado.", "error");
      break;
    case "auth/wrong-password":
      // return 'Invalid Password';
      Swal.fire({
        title: "Oops",
        text: "Contraseña incorrecta.",
        icon: "error",
        confirmButtonText: "Intentar otra vez!",
      });
      break;
    case "auth/invalid-credential":
      // return 'Invalid Password';

      Swal.fire({
        title: "Oops",
        text: "Usuario no registrado",
        icon: "error",
        confirmButtonText: "Intentar otra vez!",
      });
      break;
    case "SOMETHING_IS_WRONG":
      Swal.fire({
        title: "Oops",
        text: "Algo anda mal por favor contacta con el administrador de la página",
        icon: "error",
        confirmButtonText: "Intentar otra vez!",
      });
      break;
    default:
      return "";
  }
}

const fetchGetUserData = async (uid: string) => {
  const secondaryApp = initializeApp(firebaseConfig, "Secondary");
  const auth = getAuth(secondaryApp);
  auth.signOut();
  const firestore = getFirestore(firebaseApp);
  const docRef = doc(firestore, `Users/${uid}`);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return {
      ...docSnap.data(),
    } as any;
  } else {
    return {};
  }
};

export const getUserData = async (uid: string) => {
  // const cacheValue = getCacheValue(uid);

  // if (cacheValue) {
  //   return cacheValue;
  // }

  const res = await fetchGetUserData(uid);

  setCacheValue(uid, res, 7);
  return res;
};

export async function login({
  email,
  password,
  navigate,
}: loginProps): Promise<IUser> {
  const auth = getAuth(firebaseApp);
  return signInWithEmailAndPassword(auth, email, password)
    .then(async (userCredential) => {
      const extraData = await getUserData(userCredential.user.uid);
      if (Object.keys(extraData).length === 0) {
        formatError("auth/user-not-found");
        throw new Error();
      }
      navigate("/dashboard");
      return {
        id: userCredential.user.uid,
        email: userCredential.user.email || "",
        name: extraData?.name,
        lastName: extraData?.lastName,
        celular: extraData?.celular,
        rol: extraData?.rol,
      };
    })
    .catch((error) => {
      formatError(error.code);
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        errorCode: error.code,
        errorMessage: error.message,
      });
    });
}

export const restorePassword = async (email: string) => {
  const auth = getAuth(firebaseApp);
  sendPasswordResetEmail(auth, email)
    .then(() => {
      Swal.fire({
        title: "¡Correo enviado!",
        text: "por favor revisa también la bandeja de SPAM",
        icon: "success",
      });
    })
    .catch((error) => {
      const errorMessage =
        error.code === "auth/user-not-found"
          ? "Usuario no encontrado en nuestra base de datos, si desea registrarse en nuestra plataforma llámenos o escríbenos por WhatsApp<br>+57 3028619748"
          : `Por favor intenta nuevamente (${error.message})`;
      Swal.fire({
        title: "¡Ocurrió un error!",
        icon: "error",
        html: errorMessage,
      });
    });
};

export const handleLogout = (
  navigate: NavigateFunction,
  dispatch: AppDispatch
) => {
  const auth = getAuth(firebaseApp);
  // const currentPath = window.location.pathname;

  const ruta = window.location.pathname;
  const valorDespuesDeBarra = ruta.split("/")[1];

  auth.signOut().then(() => {
    if (valorDespuesDeBarra === "dashboard") {
      navigate("/login");
    }

    dispatch(logout());
  });
};
export const handleLogin = (
  navigate: NavigateFunction,
  dispatch: AppDispatch
) => {
  // const auth = getAuth(firebaseApp);
  // const currentPath = window.location.pathname;

  const ruta = window.location.pathname;
  const valorDespuesDeBarra = ruta.split("/")[1];

  if (
    valorDespuesDeBarra === "login" ||
    valorDespuesDeBarra === "restore-password"
  ) {
    navigate("/dashboard");
  }
};

export const signIn = (email: string, password: string) => {
  const secondaryApp = initializeApp(firebaseConfig, "Secondary");
  const auth = getAuth(secondaryApp);
  return createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const { user } = userCredential;
      setInterval(() => {
        auth.signOut();
      }, 5000);
      return user.uid;
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      Swal.fire({
        title: "¡Ocurrió un error!",
        icon: "error",
        html: `${errorCode}: ${errorMessage}`,
      });
      return Promise.reject(error);
    });
};

export const addUSer = async (
  { name, email, lastName, celular, password }: IAddUser,
  ui: string
) => {
  try {
    const createdAt = serverTimestamp();
    const uid = await signIn(email, password);
    await setDoc(doc(db, "Users", uid), {
      email,
      name,
      lastName,
      celular,
      createdAt,
    });
    showSuccessMessage({ obj, action: "add" });
    return uid;
  } catch (error) {
    showErrorMessage({ obj, error, action: "add" });
    return Promise.reject();
  }
};
