import dayjs from 'dayjs';

export function setCacheValue(key: string, value: any, days: number) {
  const item = {
    value,
    expiry: dayjs().add(days, 'day').unix(),
  };

  localStorage.setItem(key, JSON.stringify(item));
}

export function getCacheValue(key: string) {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);

  if (dayjs().unix() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}
