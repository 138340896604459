import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";

import { getCacheValue, setCacheValue } from "utils/cache";
import { IProduct } from "utils/models/IProducts";
import { cartProducts } from "../utils/cardProducts";
// type addCartProduct = {
//   checkAdd: boolean;
//   product: IProduct;
// };
type ProductState = {
  products: IProduct[];
  fechaCreacion?: string;
  showModal: boolean;

  formAction: "update" | "add";

  statusProducts: "idle" | "loading" | "success" | "fail";
  statusModal: "idle" | "loading" | "success" | "fail";
};

const initialState: ProductState = {
  products: [],
  formAction: "add",
  showModal: false,
  statusProducts: "idle",
  statusModal: "idle",
};

export const fetchAddCartProductAsync = createAsyncThunk(
  "auth/fetchAddCartProductAsync",
  async (listProducts: IProduct[]) => {
    setCacheValue("cartProducts", listProducts, 5);
    return true;
  }
);

export const fetchGetCartProductsAsync = createAsyncThunk(
  "products/fetchGetCartProductsAsync",
  async (forceFetch: boolean | undefined): Promise<IProduct[]> => {
    const cacheValue = getCacheValue("cartProducts");

    if (cacheValue) {
      let cart = cartProducts;
      cart = cacheValue;
      return cart;
    }
    setCacheValue("cartProducts", cartProducts, 5);
    return cartProducts;
  }
);

export let cartProductSlice = createSlice({
  name: "carProducts",
  initialState,
  reducers: {
    setFormAction: (
      state,
      action: PayloadAction<ProductState["formAction"]>
    ) => {
      state.formAction = action.payload;
    },

    setShowModal: (state, action: PayloadAction<ProductState["showModal"]>) => {
      state.showModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetCartProductsAsync.pending, (state) => {
        state.statusProducts = "loading";
      })
      .addCase(
        fetchGetCartProductsAsync.fulfilled,
        (state, action: PayloadAction<IProduct[]>) => {
          state.statusProducts = "success";
          state.products = action.payload;
        }
      )
      .addCase(fetchGetCartProductsAsync.rejected, (state) => {
        state.statusProducts = "fail";
      })
      .addCase(fetchAddCartProductAsync.pending, (state) => {
        state.statusModal = "loading";
      })
      .addCase(fetchAddCartProductAsync.fulfilled, (state) => {
        state.statusModal = "success";
        state.showModal = false;
      })
      .addCase(fetchAddCartProductAsync.rejected, (state) => {
        state.statusModal = "fail";
      });
  },
});

// Export actions
export const { setFormAction, setShowModal } = cartProductSlice.actions;
// Definición de selectores

export const getProducts = (state: RootState) => state.cartProducts.products;

export const getStatusCartProducts = (state: RootState) =>
  state.cartProducts.statusProducts;
export const getCartProducts = (state: RootState, query: string) => {
  if (query) {
    return state.cartProducts.products.filter(
      (d: any) =>
        d.categoria.toLowerCase().includes(query) ||
        d.nombreDescriptivo.toLowerCase().includes(query) ||
        d.subCategoria.toLowerCase().includes(query)
    );
  } else {
    return state.cartProducts.products;
  }
};
export const getStatusModal = (state: RootState) =>
  state.cartProducts.statusModal;

export default cartProductSlice.reducer;
