import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Button, Checkbox, Label, TextInput } from "flowbite-react";
import { HiMail } from "react-icons/hi";
import { rutas } from "utils/routes";
import {
  fetchGetProductsForCategoryAsync,
  getStatusProducts,
} from "../redux/productSlice";
import {
  fetchGetSubCategorysForCategoryAsync,
  getStatusSubCategorys,
} from "../redux/subCategorySlice";
import {
  fetchGetCartProductsAsync,
  getStatusCartProducts,
} from "../redux/cartProductSlice";
import LoaderBlue from "share/LoaderBlue";
import {
  fetchAddPeticioneAsync,
  getStatusModal,
} from "modules/dashboard/redux/peticionesSlice";
import { getStatusPeticiones } from "../../dashboard/redux/peticionesSlice";
import LoaderFullPage from "share/loaderFullPage/LoaderFullPage";
import {
  fetchGetBannersProductsAsync,
  getBannersProductsQuery,
  getStatusBannersProducts,
} from "modules/dashboard/redux/bannersSlice";
interface FormData {
  name: string;
  cedula: string | number;
  celular: string | number;
  cantidad: string | number;
  cuotas: string | number;
  email: string;
}
const Prestamos = () => {
  const dispatch = useAppDispatch();
  const ruta = window.location.pathname;
  const [name, setName] = useState("");
  const [cedula, setCedula] = useState("");
  const [celular, setCelular] = useState("");
  const [cantidad, setCantidad] = useState("0");
  const [cuotas, setCuotas] = useState("");
  const [email, setEmail] = useState("");
  const [formData, setFormData] = useState<FormData>({
    name: name,
    cedula: cedula,
    celular: celular,
    cantidad: cantidad,
    cuotas: cuotas,
    email: email,
  });

  const valorDespuesDeBarra = ruta.split("/")[1];

  const statusProducts = useAppSelector(getStatusProducts);
  const statusSubCategorys = useAppSelector(getStatusSubCategorys);
  const [title, setTitle] = useState("");
  const [banner, setBanner] = useState("");
  const statusCartProducts = useAppSelector(getStatusCartProducts);
  const getStatusPeticion = useAppSelector(getStatusModal);

  const statusBanners = useAppSelector(getStatusBannersProducts);
  const banners = useAppSelector((state) =>
    getBannersProductsQuery(state, valorDespuesDeBarra)
  );
  useEffect(() => {
    getTitleFromPath(valorDespuesDeBarra, rutas.front);
    if (statusBanners === "idle") {
      dispatch(fetchGetBannersProductsAsync(true));
    }
    if (statusProducts === "idle")
      dispatch(
        fetchGetProductsForCategoryAsync({
          name: valorDespuesDeBarra,
          forceFetch: true,
        })
      );
    if (statusSubCategorys === "idle")
      dispatch(
        fetchGetSubCategorysForCategoryAsync({
          name: valorDespuesDeBarra,
          forceFetch: true,
        })
      );

    if (statusCartProducts === "idle") {
      dispatch(fetchGetCartProductsAsync());
    }
  }, [
    dispatch,
    statusBanners,
    statusCartProducts,
    statusProducts,
    statusSubCategorys,
    valorDespuesDeBarra,
  ]);
  const handleChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  function getTitleFromPath(path: string, frontConfig: any) {
    // Iterar sobre los elementos dentro de frontConfig.children
    for (let i = 0; i < frontConfig.children.length; i++) {
      const child = frontConfig.children[i];

      // Si la URL del niño coincide exactamente con la ruta dada
      if (child.url === path) {
        // Devolver el título del niño
        setTitle(child.title);
        setBanner(child.imgBanner);
      }
    }

    // Si no se encuentra ninguna coincidencia, devolver null
    return null;
  }
  const enviarCorreo = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const status = await dispatch(
      fetchAddPeticioneAsync({
        ...formData,
      })
    );

    setName("");
    setCantidad("0");
    setCedula("");
    setCelular("");
    setCuotas("");
    setEmail("");

    const email = "credipopular@gmail.com";
    const asunto = encodeURIComponent("Solicitud de préstamo credipopular");
    const cuerpo = encodeURIComponent(
      "Hola \n soy " +
        formData.name +
        "con numero de cédula " +
        formData.cedula +
        " \n Me encuentro interesado en adquirir un préstamo por el valor de " +
        formData.cantidad +
        " en " +
        formData.cuotas +
        " cuotas \n Mi numero de celular es: " +
        formData.celular +
        "\n Muchas gracias "
    );

    // const mailtoLink = `https://mail.google.com/mail/?view=cm&to=${email}&su=${asunto}&body=${cuerpo}`;

    // window.open(mailtoLink, "_blank");
  };
  const handleChangeMoneda = (e: any) => {
    // Quitamos los puntos y comas del valor ingresado para obtener solo el número
    const cantidad = e.target.value.replace(/[.,]/g, "");
    setFormData({
      ...formData,
      [e.target.name]: formatNumberToColombianPesos(cantidad),
    });
  };

  const formatNumberToColombianPesos = (number: any) => {
    // Primero asegúrate de que sea un número
    if (number === "") {
      return "0";
    }
    const numberString = number.replace(/\./g, "");
    // Convertimos el número a un tipo flotante
    const cantidad = parseFloat(numberString);
    // Formateamos el número sin símbolo de moneda, solo mostrando los miles
    return cantidad.toLocaleString("es-CO").split(",")[0];
  };
  useEffect(() => {
    if (banners.length > 0) {
      setBanner(banners[0].url);
    }
  }, [valorDespuesDeBarra]);
  return (
    <div className="h-full w-full flex flex-col  justify-start items-center min-h-[80vh]">
      <div className=" relative h-[120px]  sm:h-[160px] md:h-[200px] lg:h-[300px] xl:h-[350px] 2xl:h-[400px]  w-full text-white  ">
        <img
          className="w-full h-full absolute z-0 t-0 object-fill  object-center"
          src={banner}
          alt=""
        />
        <div className="w-full h-full absolute z-0 t-0 bg-black opacity-20 "></div>
        <div className=" relative z-10 max-w-7xl h-full flex items-center justify-center mx-auto">
          <h1 className="text-2xl drop-shadow-2xl text-center z-10 font-bold sm:text-4xl md:text-5xl lg:text-6xl">
            {title}
          </h1>
          {/* <p className="mt-4 text-xl">Descubre nuestras ofertas exclusivas.</p> */}
          {/* <div className="mt-8">
            <a
              href="#"
              className="inline-block bg-white text-blue-900 py-3 px-6 rounded-lg font-semibold shadow-md hover:bg-blue-100"
            >
              Ver más
            </a>
          </div> */}
        </div>
      </div>
      <div className="flex relative container max-w-screen-lg h-full py-2 ">
        <div className="w-full  gap-4 p-4">
          <h3 className="text-center text-xl lg:text-2xl">
            Solicitud de préstamo
          </h3>
          {getStatusPeticion === "fail" ? (
            <div
              className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800"
              role="alert"
            >
              <svg
                className="flex-shrink-0 inline w-4 h-4 me-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
              <span className="sr-only">Info</span>
              <div>
                <span className="font-medium">
                  Ocurrio un error inesperado!
                </span>{" "}
                Revisa tus datos y intenta de nuevo.
              </div>
            </div>
          ) : (
            <></>
          )}
          {getStatusPeticion === "idle" || getStatusPeticion === "fail" ? (
            <form
              onSubmit={enviarCorreo}
              className="grid  grid-col-1 md:grid-cols-2 gap-4 w-full mt-4"
            >
              <div className="col-span-1">
                <div className="mb-2 block">
                  <Label htmlFor="name" value="Nombre completo" />
                </div>
                <TextInput
                  id="name"
                  type="text"
                  required
                  name="name"
                  shadow
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-1">
                <div className="mb-2 block">
                  <Label htmlFor="cedula" value="Cédula" />
                </div>
                <TextInput
                  id="cedula"
                  type="number"
                  name="cedula"
                  required
                  shadow
                  value={formData.cedula}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-1">
                <div className="mb-2 block">
                  <Label htmlFor="cedula" value="Movil" />
                </div>
                <TextInput
                  id="celular"
                  name="celular"
                  type="number"
                  required
                  shadow
                  value={formData.celular}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-1 ">
                <div className="mb-2 block">
                  <Label htmlFor="email4" value="Correo" />
                </div>
                <TextInput
                  id="email4"
                  name="email"
                  type="email"
                  icon={HiMail}
                  value={formData.email}
                  placeholder="ejemplo@gmail.com"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-span-1">
                <div className="mb-2 block">
                  <Label htmlFor="cedula" value="Cantidad" />
                </div>
                <TextInput
                  id="cantidad"
                  name="cantidad"
                  type="text" // Cambiado a tipo texto para permitir formateo dentro del mismo input
                  required
                  shadow
                  onChange={handleChangeMoneda}
                  value={formData.cantidad} // Formatear el valor antes de mostrarlo en el input
                />
              </div>
              <div className="col-span-1">
                <div className="mb-2 block">
                  <Label htmlFor="cedula" value="Cuotas" />
                </div>
                <TextInput
                  id="cuotas"
                  name="cuotas"
                  type="number"
                  required
                  shadow
                  onChange={handleChange}
                  value={formData.cuotas}
                />
              </div>
              <div className="col-span-1 md:col-span-2 flex justify-end">
                <Button type="submit">Enviar solicitud</Button>
              </div>
            </form>
          ) : (
            <></>
          )}
          {getStatusPeticion === "loading" ? <LoaderBlue></LoaderBlue> : <></>}
          {getStatusPeticion === "success" ? (
            <div
              className="flex items-center p-4 mb-4 text-sm text-green-800 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800"
              role="alert"
            >
              <svg
                className="flex-shrink-0 inline w-4 h-4 me-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
              <span className="sr-only">Info</span>
              <div>
                <span className="font-medium">Envió Completado!</span> Se ha
                enviado la solicitud correctamente
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* {statusSubCategorys === "success" && statusProducts === "success" ? (
            <TableProducts></TableProducts>
          ) : (
            <div className="h-full min-h-[500px] w-full flex justify-center items-center">
              <LoaderBlue></LoaderBlue>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default Prestamos;
