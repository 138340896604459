import React from "react";
import "./index.css";

type ISearchFieldProps = {
  value: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (q: string) => void;
};

const SearchField: React.FC<ISearchFieldProps> = ({ value, onChange }) => (
  <div className="max-w-md mx-auto">
    <div className="flex">
      <div className="relative w-full">
        <input
          translate="no"
          type="search"
          className="block px-2 py-1 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg border-s-2 border border-gray-300"
          placeholder="buscar..."
          value={value}
          onChange={(e) => onChange(e.target.value.toLowerCase())}
        />
        {/* <button
          type="submit"
          className="absolute top-0 end-0 h-full p-2 text-sm font-medium text-vinotinto rounded-e-lg border hover:bg-vinotinto focus:ring-4 hover:text-white focus:outline-none focus:ring-blue-300"
        >
          <svg
            className="w-4 h-4"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
            />
          </svg>
          <span className="sr-only">Buscar</span>
        </button> */}
      </div>
    </div>
  </div>
);
export default SearchField;
